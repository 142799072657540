import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';
import { EditorPage } from 'pages/editor';
import React, { FC, useEffect, useMemo, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import {
  AMPLITUDE_API_KEY,
  IAvatar,
  SavedVideoProject,
  showSuccessMessage
} from 'shared';
import { appStore } from './store';
import { ToastContainer } from 'react-toastify';
import { HiOutlineX } from 'react-icons/hi';
import { withLoader } from 'shared/ui/withLoader';
import { avatarStore } from 'entities/avatar';
import { voiceStore } from 'entities/voice';
import { creditStore, features, ICurrentPlanFeatures } from 'entities/credit';
import { videoStore } from 'entities/video';
import CreateAccModal from 'features/create-acc-modal/ui/CreateAccModal';
import { ChatGptModal } from 'features/chat-gpt';

type AppProps = {
  toggleBuyCreditsModal: (isOpened: boolean) => void;
  toggleUpgradeModal: (isOpened: boolean) => void;
  toggleFeatureBlockModal: (open: boolean | typeof features[number]) => void;
  toggleCreateAccModal: () => void;
  setEstimatedVideoPrice: (estimatedPrice: number | null) => void;
  planFeatures: ICurrentPlanFeatures | undefined;
  toggleUploadAvatar: () => void;
  customTalkingPhotos: IAvatar[];
};

const App: FC<AppProps> = (props) => {
  const { user, me, updateAuthStore } = appStore();

  const [isLoading, setIsLoading] = useState(true);

  const {
    fetchAvatars,
    getAvatarById,
    setCurrentAvatar,
    customTalkingPhotos,
    updateAvatarStore
  } = avatarStore();
  const { fetchVoices, voices, setCurrentVoice, setFilters } = voiceStore();
  const { fetchCredits, updateCreditStore } = creditStore();
  const { setScriptText, setVoiceover, setVideoTitle, setParentId } =
    videoStore();
  const searchParams = new URLSearchParams(document.location.search);

  const getData = async () => {
    setIsLoading(true);

    await me();

    await Promise.all([fetchAvatars(), fetchVoices(), fetchCredits()]);

    setIsLoading(false);
  };

  const checkActiveProject = async () => {
    const savedProjectJson = localStorage.getItem('savedVideoProject');
    if (savedProjectJson) {
      const savedProject = JSON.parse(savedProjectJson) as SavedVideoProject;
      const avatar = getAvatarById(savedProject.avatarId);
      if (avatar) {
        setCurrentAvatar(avatar);
      }
      if (savedProject.voiceId) {
        const voice = voices.find((v) => v.voiceId === savedProject.voiceId);
        if (voice) {
          setCurrentVoice(voice);
        }
      }
      setScriptText(savedProject.script);
      setVideoTitle(savedProject.videoTitle ?? '');
      if (savedProject.voiceOverUrl) {
        const audio = new Audio(savedProject.voiceOverUrl);
        audio.onloadedmetadata = () => {
          setVoiceover({
            name: savedProject.voiceOverName ?? '',
            link: savedProject.voiceOverUrl ?? '',
            audio
          });
        };
      }
      setParentId(savedProject.parentId);
      localStorage.removeItem('savedVideoProject');
    }
  };

  useEffect(() => {
    amplitude.init(
      AMPLITUDE_API_KEY as string,
      user?.email ?? Date.now().toString()
    );
    amplitude.track('express_page_view');

    getData().then(() => {
      const avatarId = searchParams.get('avatar');
      if (avatarId) {
        const avatar = getAvatarById(avatarId);
        if (avatar) {
          setCurrentAvatar(avatar);
        }
      } else {
        checkActiveProject();
      }
    });

    updateCreditStore('toggleBuyCreditsModal', () =>
      props.toggleBuyCreditsModal(true)
    );
    updateCreditStore('toggleUpgradeModal', () =>
      props.toggleUpgradeModal(true)
    );
    updateCreditStore(
      'toggleFeatureBlockModal',
      (open: boolean | typeof features[number]) =>
        props.toggleFeatureBlockModal(open)
    );

    updateCreditStore('setEstimatedVideoPrice', (value: number | null) =>
      props.setEstimatedVideoPrice(value)
    );
    updateAuthStore('toggleCreateAccModal', () => props.toggleCreateAccModal());

    updateAvatarStore('toggleUploadAvatar', () => props.toggleUploadAvatar());
  }, []);

  useEffect(() => {
    updateCreditStore('planFeatures', props.planFeatures);
  }, [props.planFeatures]);

  useEffect(() => {
    if (customTalkingPhotos && customTalkingPhotos.length > 0) {
      fetchAvatars();
    }
  }, [props.customTalkingPhotos]);

  const EditorWithLoader = useMemo(
    () => withLoader(EditorPage, isLoading),
    [isLoading]
  );

  useEffect(() => {
    const onResize = () => {
      const filters = voiceStore.getState().filters;

      if (window.innerWidth > 768 && filters.provider.length > 1)
        setFilters({
          ...filters,
          provider: [filters.provider[0]]
        });
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div>
      <EditorWithLoader loaderClassName="yep_ex-h-96" />
      <ChatGptModal />
      <ToastContainer
        style={{ zIndex: '2147483647' }}
        closeButton={({ closeToast }) => (
          <button type="button" onClick={closeToast}>
            <HiOutlineX
              className="yep_ex-h-5 yep_ex-w-5 yep_ex-text-gray-400 hover:yep_ex-text-gray-500"
              aria-hidden="true"
            />
          </button>
        )}
        hideProgressBar
      />
    </div>
  );
};

export default App;
