import { create } from 'zustand';
import { voiceApi } from '../api';
import {
  GenderOptions,
  IVoice,
  Language,
  VoiceFilter,
  featureFlags,
  countries,
  getErrorMessage,
  showError,
  DEFAULT_VOICE
} from 'shared';
import { getVoiceData } from '../lib';
import { createJSONStorage, persist } from 'zustand/middleware';

type VoiceState = {
  currentVoice: IVoice | null;
  voices: IVoice[];
  filteredVoices: IVoice[];
  providers: string[];
  languages: Language[];
  filters: VoiceFilter;
  genders: GenderOptions[];
  voiceError: string | null;
};

type VoiceActions = {
  setCurrentVoice: (newVoice: IVoice | null) => void;
  fetchVoices: () => void;
  setFilters: (filter: VoiceFilter) => void;
  filterVoices: () => void;
  setGenders: (genders: GenderOptions[]) => void;
  changeGender: (gender: GenderOptions) => void;
  resetGenders: () => void;
  setVoiceError: (error: string | null) => void;
  setDefaultVoice: (genderVoice: string) => void;
};

export type VoiceStore = VoiceState & VoiceActions;

export const voiceStore = create(
  persist<VoiceStore>(
    (set, get) => ({
      currentVoice: null,
      voices: [],
      filteredVoices: [],
      providers: [],
      languages: [],
      filters: {
        language: {
          name: 'English (United States)',
          country: countries.US
        },
        provider: ['All providers']
      },
      genders: [],
      voiceError: null,
      setCurrentVoice: (newVoice) => {
        set({ currentVoice: newVoice });
      },
      fetchVoices: async () => {
        get().setVoiceError(null);
        try {
          const { data } = await voiceApi.fetchAll();
          const [voices, providers, languages] = getVoiceData(data);

          set({ voices, providers, languages });

          get().filterVoices();
          get().setCurrentVoice(
            get().filteredVoices.find((v) => v.voiceId === DEFAULT_VOICE) ??
              null
          );
        } catch (error) {
          const message = getErrorMessage(
            error,
            `We couldn't load voices. Please try again later.`,
            true
          );
          showError(message);
        }
      },
      setFilters: (filter) => {
        set({ filters: filter });
        get().filterVoices();
      },
      filterVoices: () => {
        const voices = get().voices;
        const filter = get().filters;
        const genders = get().genders;
        if (!voices) return;
        const filtered = voices?.filter(
          (v) =>
            (filter.provider[0] === 'All providers' ||
              filter.provider.includes(v.provider)) &&
            (filter?.language.name === 'All languages' ||
              (filter.language.name === 'Multilingual' && v.isMultilingual) ||
              filter?.language.name === v.language) &&
            (!genders?.length || genders.includes(v.gender))
        );
        set({ filteredVoices: filtered });
      },
      setGenders: (genders) => {
        set(() => ({ genders }));
        get().filterVoices();
      },
      resetGenders: () => {
        set({ genders: [] });
      },
      changeGender: (gender) => {
        set((state) => {
          let newGenders: GenderOptions[];
          if (state.genders.includes(gender)) {
            newGenders = state.genders.filter((g) => g !== gender);
          } else {
            newGenders = [...state.genders, gender];
          }
          return {
            genders: newGenders
          };
        });
        get().filterVoices();
      },
      setVoiceError: (error) => set({ voiceError: error }),
      setDefaultVoice: (genderVoice: string) => {
        get().setCurrentVoice(
          get().voices.find((v) => v.voiceId === genderVoice) ?? null
        );
      }
    }),
    {
      name: 'voice-store',
      version: 1,
      partialize: (state) => ({
        ...state,
        voiceError: null
      }),
      migrate: (persistedState, version): VoiceStore => {
        if (version === 0) {
          (persistedState as VoiceStore).filters.provider = ['All providers'];
          (persistedState as VoiceStore).filters.language = {
            name: 'English (United States)',
            country: countries.US
          };
        }

        return persistedState as VoiceStore;
      },
      storage: createJSONStorage(() => localStorage)
    }
  )
);
