import { create } from 'zustand';
import { v4 as uuid4 } from 'uuid';
import {
  AvatarOptions,
  GenderOptions,
  IAvatar,
  SavedVideoProject,
  UploadedPhotoDetails,
  capitalize,
  featureFlags,
  getErrorMessage,
  showError,
  LOGOUT_RESET,
  logger
} from 'shared';
import { avatarApi } from '../api';
import { filterAvatars } from '../lib';
import { uploadedPhotoMock } from './mock/uploadedPhotos';
import { createJSONStorage, persist } from 'zustand/middleware';

type AvatarState = {
  currentAvatar: IAvatar | null;
  publicAvatars: IAvatar[];
  customTalkingPhotos: IAvatar[];
  genderFilters: GenderOptions[];
  typeFilters: AvatarOptions[];
  filteredPublicAvatars: IAvatar[];
  filteredCustomTalkingPhotos: IAvatar[];
  isCropped: boolean;
  avatarError: string | null;
  isDynamic: boolean;
  showCustomPhotoModal: boolean;
};

type AvatarActions = {
  setCurrentAvatar: (newAvatar: IAvatar | null) => void;
  fetchAvatars: () => Promise<void>;
  filterAvatars: (searchTerm?: string) => void;
  setGenderFilters: (value: GenderOptions) => void;
  resetGenderFilter: () => void;
  setTypeFilters: (type: AvatarOptions) => void;
  resetTypeFilter: () => void;
  deleteCustomTalkingPhoto: (avatarId: string) => Promise<unknown>;
  setAvatarError: (error: string | null) => void;
  addUploadedPhoto: (avatar: UploadedPhotoDetails) => Promise<boolean>;
  setIsDynamic: (newDynamicState: boolean) => void;
  setIsCropped: (isCropped: boolean) => void;
  setShowCustomPhotoModal: (show: boolean) => void;
  getAvatarById: (avatarId: string) => IAvatar | undefined;
  toggleUploadAvatar: () => void;
  updateAvatarStore: (key: string, value: any) => void;
  resetStore: () => void;
};

window.addEventListener(LOGOUT_RESET, () => {
  avatarStore.getState().resetStore();
});

export type AvatarStore = AvatarState & AvatarActions;

// TODO: Add uploaded custom avatars, remove talking photos

export const avatarStore = create<AvatarStore>()(
  persist(
    (set, get) => ({
      updateAvatarStore: (key, value) => {
        set({ [key]: value });
      },
      toggleUploadAvatar: () => {},
      currentAvatar: null,
      publicAvatars: [],
      talkingPhotos: [],
      customTalkingPhotos: [],
      genderFilters: [],
      typeFilters: [],
      filteredTalkingPhotos: [],
      filteredPublicAvatars: [],
      filteredCustomTalkingPhotos: [],
      isCropped: false,
      avatarError: null,
      isDynamic: false,
      setCurrentAvatar: (newAvatar) => {
        if (newAvatar) {
          const newDynamicState = newAvatar.talkingPhotoUpperBody
            ? get().isDynamic
            : false;

          get().setIsDynamic(newDynamicState);
        }
        set(() => ({
          currentAvatar: newAvatar
        }));
      },
      fetchAvatars: async () => {
        get().setAvatarError(null);
        try {
          const { data: avatars } = await avatarApi.fetchAll();

          const publicAvatars = avatars.filter((a) => !a.customAvatar);
          const customTalkingPhotos = avatars.filter((a) => a.customAvatar);

          publicAvatars.sort((a, b) => {
            if (a.photorealistic && !b.photorealistic) {
              return -1;
            }
            if (!a.photorealistic && b.photorealistic) {
              return 1;
            }
            if (a.photorealistic && b.photorealistic) {
              return a.name.localeCompare(b.name);
            }

            return 0;
          });

          set({
            publicAvatars,
            customTalkingPhotos
          });
          get().filterAvatars();
        } catch (error) {
          const message = getErrorMessage(
            error,
            "We couldn't load avatars. Please try again later.",
            true
          );
          showError(message);
        }
      },

      filterAvatars: (searchTerm?: string) => {
        const genderFilters = get().genderFilters;

        const filteredCustomTalkingPhotos = filterAvatars(
          get().customTalkingPhotos,
          genderFilters
        ).filter((avatar) =>
          avatar.name.toLowerCase().includes(searchTerm?.toLowerCase() || '')
        );

        const filteredPublicAvatars = filterAvatars(
          get().publicAvatars,
          genderFilters
        ).filter((avatar) =>
          avatar.name.toLowerCase().includes(searchTerm?.toLowerCase() || '')
        );

        set({
          filteredPublicAvatars,
          filteredCustomTalkingPhotos
        });
      },

      setGenderFilters: (value) => {
        const genderFilters = get().genderFilters;
        if (genderFilters.includes(value)) {
          set({
            genderFilters: genderFilters.filter((filter) => filter !== value)
          });
        } else {
          set({
            genderFilters: [...genderFilters, value]
          });
        }
        get().filterAvatars();
      },
      resetGenderFilter: () => {
        set({
          genderFilters: []
        });
        get().filterAvatars();
      },
      setTypeFilters: (type) => {
        const newFilter = [...get().typeFilters];
        if (newFilter.includes(type)) {
          newFilter.splice(newFilter.indexOf(type), 1);
        } else {
          newFilter.push(type);
        }
        set({ typeFilters: newFilter });
      },
      resetTypeFilter: () => {
        set({ typeFilters: [] });
      },
      deleteCustomTalkingPhoto: async (avatarId: string) => {
        if (get().currentAvatar?.id === avatarId) {
          set({ currentAvatar: null });
        }
        const avatar = get().customTalkingPhotos.find((a) => a.id === avatarId);
        if (!avatar) {
          return;
        }
        set({
          customTalkingPhotos: get().customTalkingPhotos.filter(
            (a) => a.id !== avatarId
          )
        });
        if (avatar.isUploaded) {
          get().filterAvatars();
          return;
        }
        const response = await avatarApi.deleteCustomTalkingPhoto(avatarId);
        get().fetchAvatars();
        return response;
      },
      setAvatarError: (error) => {
        set({ avatarError: error });
      },
      addUploadedPhoto: async (uploadedPhotoData) => {
        try {
          const { data } = await avatarApi.createCustomPhoto(uploadedPhotoData);
          await get().fetchAvatars();
          get().setCurrentAvatar(data);
          return true;
        } catch (error) {
          const message = getErrorMessage(
            error,
            `We couldn't upload your custom avatar. Please try again later.`,
            true
          );
          showError(message);

          return false;
        }
      },
      setIsDynamic: (newDynamicState) => {
        set({ isDynamic: newDynamicState });
      },
      setIsCropped: (isCropped) => {
        set({ isCropped });
      },
      showCustomPhotoModal: false,
      setShowCustomPhotoModal: (show) => {
        set({ showCustomPhotoModal: show });
      },
      getAvatarById: (avatarId) => {
        const avatars = [...get().publicAvatars, ...get().customTalkingPhotos];
        return avatars.find((a) => a.id === avatarId);
      },
      resetStore: () => {
        set({
          currentAvatar: null,
          publicAvatars: [],
          customTalkingPhotos: [],
          genderFilters: [],
          typeFilters: [],
          filteredPublicAvatars: [],
          filteredCustomTalkingPhotos: [],
          isCropped: false,
          avatarError: null,
          isDynamic: false
        });
      }
    }),
    {
      name: 'avatar-store',
      storage: createJSONStorage(() => localStorage),
      partialize: (keys) => ({
        currentAvatar: keys.currentAvatar,
        genderFilters: keys.genderFilters,
        typeFilters: keys.typeFilters,
        isDynamic: keys.isDynamic,
        isCropped: featureFlags.cropActor ? keys.isCropped : false
      })
    }
  )
);
