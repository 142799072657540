import { CanvasControls } from 'widgets/CanvasControls';
import { Script } from 'widgets/Script';
import { useState } from 'react';
import { VoiceoverWidget } from 'widgets/Voiceover';

const tabs = ['Script', 'Voiceover'];
export const MobileScriptWrapper = () => {
  const [activeTab, setActiveTab] = useState('Script');
  return (
    <div className="yep_ex-h-full yep_ex-flex yep_ex-flex-col">
      <CanvasControls
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
      />
      <div className="yep_ex-p-4 lg:yep_ex-p-0 yep_ex-flex yep_ex-flex-col yep_ex-h-full yep_ex-overflow-y-auto">
        {activeTab === 'Script' && <Script />}
        {activeTab === 'Voiceover' && <VoiceoverWidget />}
      </div>
    </div>
  );
};
