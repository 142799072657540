import { RefObject, useCallback, useEffect } from 'react';
import { canvasStore } from 'entities/canvas';
import { featureFlags } from 'shared';

export const useCanvasSize = (canvasWrapperRef: RefObject<HTMLDivElement>) => {
  const { canvas, currentFormat } = canvasStore();

  const resize = useCallback(() => {
    if (featureFlags.canvas === false) return;
    if (canvas && canvasWrapperRef.current) {
      const width = canvasWrapperRef.current.offsetWidth;
      const height = canvasWrapperRef.current.offsetHeight;

      // if (width / height > currentFormat) {
      //   width = height * currentFormat;
      // } else {
      //   height = width / currentFormat;
      // }

      const scale = width / canvasWrapperRef.current.offsetWidth;
      let zoom = canvas.getZoom();
      zoom *= scale;
      canvas.setDimensions({ width, height });
      canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    }
  }, [canvas, canvasWrapperRef.current, currentFormat]);

  useEffect(() => {
    resize();
    if (featureFlags.canvas) window.addEventListener('resize', resize);

    return () => {
      if (featureFlags.canvas) {
        window.removeEventListener('load', resize, false);
        window.removeEventListener('resize', resize);
      }
    };
  }, []);

  useEffect(() => {
    resize();
  }, [currentFormat, canvas]);
};
