import React, { useState } from 'react';
import clsx from 'clsx';
import { FaInfoCircle } from 'react-icons/fa';
import { IAvatar, Modal } from 'shared';
import CustomTalkingPhotoInfo from './CustomTalkingPhotoInfo';
import TooltipPortal from 'shared/ui/TooltipPortal';

type AvatarItemProps = {
  avatar: IAvatar;
  onChooseAvatar: (avatar: IAvatar) => void;
  isActive?: boolean;
  openModal?: (avatar: IAvatar) => void;
};

export const AvatarItem: React.FC<AvatarItemProps> = ({
  avatar,
  onChooseAvatar,
  isActive
}) => {
  const imgUrl =
    avatar.inputImageUrl ?? avatar.menuImageUrl ?? avatar.rawImageUrl;

  const [isInfoOpened, setIsInfoOpened] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const showTooltip = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const isMobile = window.innerWidth <= 767;
    setTooltipPosition({
      top: rect.top + window.scrollY + 24, // Смещение по вертикали
      left: rect.left + window.scrollX + (isMobile ? -32 : 8) // Смещение по горизонтали
    });
    setIsTooltipVisible(true);
  };

  const hideTooltip = () => {
    setIsTooltipVisible(false);
  };

  return (
    <>
      <div
        onClick={() => onChooseAvatar(avatar)}
        className={clsx(
          `yep_ex-relative yep_ex-group`,
          isActive ? 'yep_ex-cursor-default' : 'yep_ex-cursor-pointer'
        )}
      >
        {imgUrl && (
          <div className="yep_ex-relative">
            <img
              src={imgUrl}
              alt={avatar.name}
              className={clsx(
                `yep_ex-w-full yep_ex-aspect-square yep_ex-border yep_ex-rounded-lg yep_ex-object-cover yep_ex-object-top`,
                isActive
                  ? 'yep_ex-border-neutral-400'
                  : 'yep_ex-border-neutral-100'
              )}
            />
            {avatar?.photorealistic && (
              <div
                className="yep_ex-absolute yep_ex-left-2 yep_ex-bottom-2 yep_ex-rounded-full yep_ex-bg-neutral-900/80 yep_ex-text-yellow-400 yep_ex-px-1.5 yep_ex-py-0.5 yep_ex-text-xs yep_ex-font-semibold yep_ex-group"
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
              >
                <span className="hover:yep_ex-cursor-pointer yep_ex-uppercase">
                  HQ
                </span>
              </div>
            )}
          </div>
        )}

        {isTooltipVisible && (
          <TooltipPortal position={tooltipPosition}>
            High-quality, photorealistic avatars
          </TooltipPortal>
        )}

        {avatar.talkingPhoto && avatar.customAvatar && (
          <button
            onClick={() => {
              setIsInfoOpened(true);
            }}
            className={clsx(
              'yep_ex-absolute yep_ex-w-5 yep_ex-h-5 !yep_ex-rounded-full yep_ex-top-1 yep_ex-right-0.5 yep_ex-justify-center yep_ex-items-center yep_ex-transition-opacity yep_ex-hidden group-hover:yep_ex-flex'
            )}
          >
            <FaInfoCircle className="yep_ex-text-sm yep_ex-bg-white yep_ex-rounded-full" />
          </button>
        )}

        <div
          className={clsx(
            'yep_ex-text-sm yep_ex-overflow-hidden yep_ex-whitespace-nowrap yep_ex-overflow-ellipsis yep_ex-pt-0.5 yep_ex-text-gray-600'
          )}
        >
          {avatar.name}
        </div>
      </div>

      {isInfoOpened && (
        <Modal
          onClose={() => setIsInfoOpened(false)}
          open={isInfoOpened}
          noPadding
          withCloseButton
          className="md:yep_ex-max-w-[480px]"
        >
          <CustomTalkingPhotoInfo
            avatar={avatar}
            onClose={() => setIsInfoOpened(false)}
          />
        </Modal>
      )}
    </>
  );
};
