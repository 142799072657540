import { useRef, useState } from 'react';
import { VscChevronDown } from 'react-icons/vsc';
import { capitalize } from '../lib';
import { useClickOutside } from '../api/useClickOutside';

type SelectInputProps = {
  name: string;
  options: string[];
  value: string | null;
  onChange: (value: any) => void;
  disabled?: boolean;
  className?: string;
  error?: string | boolean;
  showFont?: boolean;
  scroll?: boolean;
  placeholder?: string;
  borderColorClass?: string;
};

export const SelectInput = ({
  name,
  options,
  value,
  onChange,
  placeholder
}: SelectInputProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(selectRef, setIsOpen);

  return (
    <div className={`yep_ex-group yep_ex-relative yep_ex-mb-2 yep_ex-w-full`}>
      <div
        className="yep_ex-text-base yep_ex-border yep_ex-border-neutral-300 yep_ex-rounded-md yep_ex-text-neutral-500 focus-visible:yep_ex-outline-none 
        disabled:yep_ex-bg-white disabled:yep_ex-opacity-50 yep_ex-text-md focus:yep_ex-outline-none yep_ex-shadow-sm focus:yep_ex-ring-secondary 
        focus:yep_ex-border-secondary yep_ex-cursor-pointer yep_ex-relative yep_ex-flex yep_ex-items-center yep_ex-justify-between yep_ex-w-full yep_ex-px-3 yep_ex-py-2"
        onClick={() => setIsOpen((prevState) => !prevState)}
        ref={selectRef}
      >
        {value ? <span>{capitalize(value)}</span> : <span>{placeholder}</span>}
        <VscChevronDown
          className={`yep_ex-right-4 yep_ex-absolute ${
            isOpen && 'yep_ex-transform yep_ex-rotate-180'
          } `}
        />
        <div
          className={`yep_ex-absolute yep_ex-w-full yep_ex-max-h-[10rem] yep_ex-top-[3.25rem] yep_ex-py-1 yep_ex-px-1 yep_ex-left-0 yep_ex-overflow-y-scroll 
          yep_ex-bg-white yep_ex-z-50 yep_ex-border yep_ex-border-neutral-300 yep_ex-rounded-lg ${
            isOpen ? 'yep_ex-block' : 'yep_ex-hidden'
          }`}
        >
          {options?.map((opt) => (
            <div
              onClick={() => onChange(opt)}
              key={`${name}-${opt}`}
              className={`yep_ex-py-2 yep_ex-px-2 yep_ex-flex yep_ex-justify-between ${
                opt === value && 'yep_ex-bg-slate-200 yep_ex-rounded-lg'
              }`}
            >
              <span>{opt}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
