import { createRef, useState } from 'react';
import {
  HexAlphaColorPicker,
  HexColorInput,
  HexColorPicker
} from 'react-colorful';
import { useClickOutside } from 'shared/api';
import { ReactComponent as BgColorPlaceholder } from './img/bgColorPlaceholder.svg';

interface ColorSelectProps {
  color: string | undefined;
  onChange: (color: string) => void;
  disabled?: boolean;
  className?: string;
  pickerPosition?: 'left' | 'right' | 'center';
  disableTransparent?: boolean;
}

const ColorSelect = ({
  color,
  onChange,
  disabled,
  className,
  pickerPosition = 'center',
  disableTransparent = false
}: ColorSelectProps) => {
  const [isShown, setIsShown] = useState(false);
  const ref = createRef<HTMLDivElement>();

  useClickOutside(ref, setIsShown);

  return (
    <>
      <div
        className={`yep_ex-block yep_ex-p-1 yep_ex-text-sm yep_ex-border yep_ex-border-tertiary disabled:yep_ex-border-gray-100 focus:yep_ex-outline-none 
        yep_ex-shadow-sm focus:yep_ex-ring-indigo-500 focus:yep_ex-border-indigo-500 sm:yep_ex-text-sm yep_ex-rounded-md yep_ex-bg-white ${
          disabled
            ? 'yep_ex-cursor-default yep_ex-pointer-events-none yep_ex-opacity-50'
            : 'yep_ex-cursor-pointer hover:yep_ex-bg-gray-50 '
        } ${className}`}
      >
        {color && color === '#ffffff' ? (
          <BgColorPlaceholder
            className="yep_ex-rounded-md yep_ex-border yep_ex-bg-contain"
            onClick={() => setIsShown((prevState) => !prevState)}
          />
        ) : (
          <div
            style={{ backgroundColor: color }}
            onClick={() => setIsShown((prevState) => !prevState)}
            className={`yep_ex-w-full yep_ex-h-full yep_ex-rounded-md yep_ex-border yep_ex-border-gray-100 yep_ex-mb-2`}
          ></div>
        )}
        {isShown && (
          <div ref={ref} className="yep_ex-relative yep_ex-z-40">
            <div
              className={`yep_ex-w-[13.5rem] yep_ex-rounded-xl yep_ex-mx-auto yep_ex-p-2 yep_ex-content-center yep_ex-bg-white yep_ex-block yep_ex-shadow-md ${
                pickerPosition === 'left' && '-yep_ex-ml-44'
              } ${pickerPosition === 'right' && 'yep_ex-ml-0'} ${
                pickerPosition === 'center' && '-yep_ex-ml-24'
              }`}
            >
              {disableTransparent ? (
                <HexColorPicker
                  className="content-center"
                  color={color}
                  onChange={onChange}
                />
              ) : (
                <HexAlphaColorPicker
                  className="content-center"
                  color={color}
                  onChange={onChange}
                />
              )}
              <HexColorInput
                className="yep_ex-mt-2 yep_ex-block yep_ex-mx-auto yep_ex-px-2 yep_ex-bg-color-grey yep_ex-uppercase yep_ex-text-center yep_ex-max-w-full"
                prefixed={true}
                color={color}
                onChange={onChange}
                alpha={disableTransparent}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ColorSelect;
