import { FC } from 'react';
import { IMessage } from 'shared';
import { appStore } from 'app/store';

export const ChatUserMessage: FC<
  {
    isPrevSameRole: boolean;
  } & IMessage
> = ({ content, isPrevSameRole }) => {
  const user = appStore((state) => state.user);
  return (
    <div className="yep_ex-flex yep_ex-gap-4 yep_ex-ml-auto">
      <div className="yep_ex-rounded-lg yep_ex-bg-neutral-50 yep_ex-px-4 yep_ex-py-2 yep_ex-text-sm yep_ex-text-neutral-600 yep_ex-ml-14">
        {content}
      </div>
      <div className="yep_ex-min-w-[2.5rem] yep_ex-w-10 yep_ex-h-10 yep_ex-rounded-full yep_ex-overflow-hidden">
        {!isPrevSameRole && user?.name && user.name.trim()[0] && (
          <div className="yep_ex-w-full yep_ex-h-full yep_ex-rounded-full yep_ex-flex yep_ex-justify-center yep_ex-items-center yep_ex-border-2 yep_ex-border-primary-200 yep_ex-bg-primary-100 yep_ex-font-medium yep_ex-text-primary-600">
            {user.name.trim()[0].toUpperCase()}
          </div>
        )}
      </div>
    </div>
  );
};
