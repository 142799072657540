import type { FC } from 'react';
import type { Tab } from 'features/navigation';
import clsx from 'clsx';

interface Props<T extends Tab = Tab> {
  tabs: T[] | readonly T[];
  activeTab: T['name'];
  toggleTab: (value: any) => void;
}

const MobileTabsGroup: FC<Props> = ({ tabs, activeTab, toggleTab }) => {
  return (
    <ul className="yep_ex-min-h-[4.25rem] yep_ex-w-full yep_ex-flex yep_ex-justify-between yep_ex-border-t yep_ex-border-gray-200 yep_ex-bg-white">
      {tabs.map((t) => {
        const title = typeof t.title === 'string' ? t.title : t.title.mobile;
        return (
          <li
            key={t.name}
            className="yep_ex-h-[4.25rem] yep_ex-list-none yep_ex-flex-1 yep_ex-text-xs"
          >
            <button
              className={clsx(
                'yep_ex-h-full yep_ex-flex yep_ex-flex-col yep_ex-items-center yep_ex-w-full yep_ex-justify-center yep_ex-mx-auto disabled:yep_ex-opacity-50',
                t.name === activeTab
                  ? 'yep_ex-bg-neutral-50'
                  : 'yep_ex-bg-none',
                !t.disabled && 'hover:yep_ex-bg-neutral-50'
              )}
              onClick={() => {
                toggleTab(t.name);
              }}
              disabled={t.disabled}
            >
              <img src={t.icon} className="yep_ex-mb-1.5" alt={title} />
              {title}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default MobileTabsGroup;
