import { FC } from 'react';

interface SingleCheckBoxProps<T extends string = string> {
  label: string;
  onClick: (gender: T) => void;
  value: boolean;
  className?: string;
}
const SingleCheckBox: FC<SingleCheckBoxProps> = ({
  label,
  onClick,
  value,
  className = ''
}) => {
  return (
    <label className={`yep_ex-flex yep_ex-items-center ${className}`}>
      <input
        type="checkbox"
        className="yep_ex-hidden"
        onChange={(e) => {
          onClick(e.target.value);
        }}
        checked={value}
      />
      <svg
        className="yep_ex-w-4 yep_ex-h-4 yep_ex-inline yep_ex-mr-2"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          fill="#FCF5FF"
          className={`${value ? 'yep_ex-visible' : 'yep_ex-hidden'}`}
        />
        <path
          d="M12 5L6.5 10.5L4 8"
          stroke="#C34DFF"
          strokeWidth="1.6666"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={`${value ? 'yep_ex-visible' : 'yep_ex-hidden'}`}
        />
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          stroke="#D4D4D4"
          fill={`${value ? 'none' : '#fff'}`}
        />
      </svg>
      <span className="yep_ex-text-sm yep_ex-whitespace-nowrap">{label}</span>
    </label>
  );
};

export default SingleCheckBox;
