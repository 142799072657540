import { useRef } from 'react';
import { FabricCanvas } from './FabricCanvas';
import { useCanvasSize } from 'features/resize-canvas';
import { featureFlags } from 'shared';
import { PlainCanvas } from './PlainCanvas';

export const CanvasWrapper = () => {
  // const { currentFormat } = canvasStore();
  // const currentAvatar = avatarStore((state) => state.currentAvatar);

  const canvasWrapperRef = useRef<HTMLDivElement>(null);

  useCanvasSize(canvasWrapperRef);

  return (
    <>
      <div className="yep_ex-bg-neutral-200 yep_ex-flex yep_ex-justify-center yep_ex-items-center yep_ex-py-3 yep_ex-px-3 lg:yep_ex-px-12 yep_ex-h-[16rem] md:yep_ex-h-[24rem] lg:yep_ex-h-[32rem]">
        {/* <div
          ref={canvasWrapperRef}
          className={clsx(
            'yep_ex-rounded yep_ex-mx-auto',
            !currentAvatar?.isUploaded && 'yep_ex-bg-white',
            currentFormat === 1.77777777778 &&
              'yep_ex-aspect-video yep_ex-w-full yep_ex-max-w-[50rem]',
            currentFormat !== 1.77777777778 && 'yep_ex-h-full',
            currentFormat === 0.5625 && 'yep_ex-aspect-[9/16]',
            currentFormat === 0.8 && 'yep_ex-aspect-[4/5]',
            currentFormat === 1 && 'yep_ex-aspect-square'
          )}
        > */}
        {featureFlags.canvas ? <FabricCanvas /> : <PlainCanvas />}
        {/* </div> */}
      </div>
    </>
  );
};
