import clsx from 'clsx';
import { BsFilter } from 'react-icons/bs';
import { avatarStore } from 'entities/avatar';
import {
  AvatarOptions,
  Checkbox,
  Divider,
  Dropdown,
  GenderOptions
} from 'shared';
import { Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

type Props = {
  activeTab: string;
};

export const AvatarFilters = ({ activeTab }: Props) => {
  const {
    setGenderFilters,
    genderFilters,
    resetGenderFilter,
    typeFilters,
    resetTypeFilter,
    setTypeFilters
  } = avatarStore((state) => ({
    setGenderFilters: state.setGenderFilters,
    genderFilters: state.genderFilters,
    resetGenderFilter: state.resetGenderFilter,
    typeFilters: state.typeFilters,
    resetTypeFilter: state.resetTypeFilter,
    setTypeFilters: state.setTypeFilters
  }));

  const [mobileOpen, setMobileOpen] = useState(false);

  const filtersLength = typeFilters.length + genderFilters.length;
  return (
    <>
      <div className="yep_ex-hidden yep_ex-pb-2 md:yep_ex-block">
        <Dropdown
          icon={<BsFilter className="yep_ex-w-5 yep_ex-h-5" />}
          className="yep_ex-static md:yep_ex-relative"
          buttonClassName={
            filtersLength > 0
              ? 'yep_ex-text-primary-700 yep_ex-border-primary-300'
              : ''
          }
        >
          <div className="yep_ex-pt-5 yep_ex-pb-4">
            <div className="yep_ex-pl-3.5 yep_ex-pr-4 yep_ex-flex yep_ex-justify-between yep_ex-w-full yep_ex-items-baseline">
              <span className="yep_ex-text-sm yep_ex-font-inter yep_ex-font-semibold">
                Filter
              </span>
              <button
                className="yep_ex-text-sm yep_ex-font-inter yep_ex-font-semibold disabled:yep_ex-text-gray-300 yep_ex-text-primary-700"
                onClick={() => {
                  resetTypeFilter();
                  resetGenderFilter();
                }}
                disabled={filtersLength === 0}
              >
                Reset
              </button>
            </div>
            <Divider className="yep_ex-w-full yep_ex-my-4" />
            <div className="yep_ex-flex yep_ex-flex-col yep_ex-items-start yep_ex-gap-4 yep_ex-justify-evenly yep_ex-mt-5 yep_ex-pl-3.5 yep_ex-pr-4">
              <div className="yep_ex-text-sm yep_ex-font-semibold yep_ex-text-neutral-900">
                Gender
              </div>
              <Checkbox
                label="Male"
                onClick={(value) => setGenderFilters(value as GenderOptions)}
                choosenValues={genderFilters}
                value="male"
              />
              <Checkbox
                label="Female"
                onClick={(value) => setGenderFilters(value as GenderOptions)}
                choosenValues={genderFilters}
                value="female"
              />
            </div>

            {activeTab === 'Talking Photos' && (
              <>
                <Divider className="yep_ex-w-full yep_ex-my-4" />

                <div className="yep_ex-flex yep_ex-flex-col yep_ex-items-start yep_ex-gap-4 yep_ex-justify-evenly yep_ex-mt-5 yep_ex-pl-3.5 yep_ex-pr-4">
                  <div className="yep_ex-text-sm yep_ex-font-semibold yep_ex-text-neutral-900">
                    Source
                  </div>
                  <Checkbox
                    label="Stock"
                    onClick={(value) => setTypeFilters(value as AvatarOptions)}
                    choosenValues={typeFilters}
                    value="stock"
                  />

                  <Checkbox
                    label="My Avatars"
                    onClick={(value) => setTypeFilters(value as AvatarOptions)}
                    choosenValues={typeFilters}
                    value="my avatars"
                  />
                </div>
              </>
            )}
          </div>
        </Dropdown>
      </div>

      <div className="yep_ex-block md:yep_ex-hidden">
        <button
          className={clsx(
            `yep_ex-flex yep_ex-items-center yep_ex-gap-2 yep_ex-justify-center yep_ex-border yep_ex-rounded-lg yep_ex-h-10 yep_ex-py-2.5 yep_ex-px-4 yep_ex-w-16`,
            filtersLength > 0
              ? 'yep_ex-text-primary-700 yep_ex-border-primary-300'
              : 'yep_ex-border-gray-300 yep_ex-text-neutral-700'
          )}
          onClick={() => setMobileOpen(true)}
        >
          <BsFilter className="yep_ex-w-5 yep_ex-h-5" />
        </button>
      </div>
      <Transition
        enter="yep_ex-transition yep_ex-duration-100 yep_ex-ease-out"
        enterFrom="yep_ex-transform yep_ex-scale-95 yep_ex-opacity-0"
        enterTo="yep_ex-transform yep_ex-scale-100 yep_ex-opacity-100"
        leave="yep_ex-transition yep_ex-duration-75 yep_ex-ease-out"
        leaveFrom="yep_ex-transform yep_ex-scale-100 yep_ex-opacity-100"
        leaveTo="yep_ex-transform yep_ex-scale-95 yep_ex-opacity-0"
        as={Fragment}
        show={mobileOpen}
      >
        <div className="yep_ex-z-50  yep_ex-h-full yep_ex-bg-white yep_ex-top-0 yep_ex-left-0 yep_ex-absolute yep_ex-right-0 yep_ex-w-full yep_ex-border-neutral-200">
          <div className="yep_ex-min-h-[3.5rem] yep_ex-border yep_ex-border-neutral-200 yep_ex-px-4 yep_ex-flex yep_ex-justify-between yep_ex-w-full yep_ex-items-center">
            <span className="yep_ex-text-sm yep_ex-font-inter yep_ex-font-semibold">
              <button
                onClick={() => setMobileOpen(false)}
                className="yep_ex-stroke-neutral-600 yep_ex-text-neutral-600 yep_ex-flex yep_ex-items-center yep_ex-justify-center"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="yep_ex-mr-2"
                >
                  <path
                    d="M8.33333 15.8346L2.5 10.0013M2.5 10.0013L8.33333 4.16797M2.5 10.0013L17.5 10.0013"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Back
              </button>
            </span>
            <button
              className="yep_ex-text-sm yep_ex-font-inter yep_ex-font-semibold yep_ex-text-primary-700 disabled:yep_ex-text-gray-300 disabled:yep_ex-pointer-events-none"
              onClick={() => {
                resetTypeFilter();
                resetGenderFilter();
              }}
              disabled={typeFilters.length === 0 && genderFilters.length === 0}
            >
              Reset
            </button>
          </div>
          <div className="yep_ex-flex yep_ex-items-center ">
            <div className="yep_ex-pt-4 yep_ex-flex yep_ex-flex-col yep_ex-items-start yep_ex-gap-5 yep_ex-pl-3.5 yep_ex-pr-4 yep_ex-overflow-y-auto yep_ex-pb-1">
              <div className="yep_ex-text-sm yep_ex-font-semibold yep_ex-text-neutral-900">
                Gender
              </div>
              <Checkbox
                label="Male"
                onClick={(value) => setGenderFilters(value as GenderOptions)}
                choosenValues={genderFilters}
                value="male"
              />
              <Checkbox
                label="Female"
                onClick={(value) => setGenderFilters(value as GenderOptions)}
                choosenValues={genderFilters}
                value="female"
              />
            </div>
            {activeTab === 'Talking Photos' && (
              <>
                {/* <Divider className="yep_ex-w-full yep_ex-my-4" /> */}

                <div className="yep_ex-pt-4 yep_ex-flex yep_ex-flex-col yep_ex-items-start yep_ex-gap-5 yep_ex-pl-3.5 yep_ex-pr-4 yep_ex-overflow-y-auto yep_ex-pb-1">
                  <div className="yep_ex-text-sm yep_ex-font-semibold yep_ex-text-neutral-900">
                    Source
                  </div>
                  <Checkbox
                    label="Stock"
                    onClick={(value) => setTypeFilters(value as AvatarOptions)}
                    choosenValues={typeFilters}
                    value="stock"
                  />

                  <Checkbox
                    label="My Avatars"
                    onClick={(value) => setTypeFilters(value as AvatarOptions)}
                    choosenValues={typeFilters}
                    value="my avatars"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Transition>
    </>
  );
};
