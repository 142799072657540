/// <reference types="react-scripts" />
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ENVIRONMENT } from 'shared';
import App from 'app/App';

// if (ENVIRONMENT !== 'development') {
//   Sentry.init({
//     dsn: 'https://15d15b87a58242e28c3f95baa1449432@o230804.ingest.sentry.io/4505391599452160',
//     integrations: [
//       new Sentry.BrowserTracing({
//         tracePropagationTargets: ['localhost', 'https:yourserver.io/api/']
//       }),
//       new Sentry.Replay()
//     ],
//     environment: process.env.REACT_APP_ENVIRONMENT,
//     tracesSampleRate:
//       process.env.REACT_APP_ENVIRONMENT === 'production' ? 0.2 : 1.0,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0
//   });
// }

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
    <App
      toggleBuyCreditsModal={() => {
        alert('buy credits');
      }}
      toggleUpgradeModal={() => {
        alert('upgrade');
      }}
      toggleFeatureBlockModal={(feature) => {
        alert(`feature block ${feature}`);
      }}
      toggleCreateAccModal={() => {
        alert('create acc');
      }}
      setEstimatedVideoPrice={() => {
        alert('setEstimate');
      }}
      customTalkingPhotos={[]}
      toggleUploadAvatar={() => {
        alert('uploadAvatar');
      }}
      planFeatures={{
        expressAllowed: false,
        proAllowed: false,
        agentsAllowed: false,
        extraCreditsAllowed: false,
        apiAllowed: false,
        premiumVoicesAllowed: false,
        speechAllowed: true,
        studioAllowed: false
      }}
    />
  </BrowserRouter>
);
