import { useState } from 'react';
import { fileApi } from '../api';
import { getErrorMessage, showError } from 'shared';

export const useUploadFile = () => {
  const [isLoading, setIsLoading] = useState(false);

  const urlToFile = async (url: string, filename: string, mimeType: string) => {
    const data = await fetch(url);
    const buffer = await data.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  };

  const uploadFile = async (file: File) => {
    if (!file) {
      showError('No file chosen');
      return;
    }
    setIsLoading(true);

    try {
      const data = await fileApi.upload(file);
      return data;
    } catch (error) {
      const message = getErrorMessage(
        error,
        `We couldn't upload file. Please try again later`
      );
      showError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFileWithProgress = async (
    file: File,
    onProgressChange: (progress: number) => void
  ) => {
    if (!file) {
      return;
    }
    setIsLoading(true);

    try {
      const data = await fileApi.uploadWithProgress(
        file,
        (e) =>
          e.total && onProgressChange(Math.round((100 * e.loaded) / e.total))
      );
      return data;
    } catch (error) {
      const message = getErrorMessage(
        error,
        `We couldn't upload file. Please try again later`
      );
      showError(message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    urlToFile,
    uploadFile,
    uploadFileWithProgress,
    isLoading
  };
};
