import clsx from 'clsx';
import React from 'react';

type RadioButtonProps = {
  id: string;
  name: string;
  value: string | boolean;
  label: string;
  checked: boolean;
  onChange: (value: string | boolean) => void;
  disabled: boolean;
};

export const RadioButton = ({
  id,
  name,
  value,
  label,
  checked,
  onChange,
  disabled
}: RadioButtonProps) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <label
      htmlFor={id}
      className={clsx(
        'yep_ex-block yep_ex-relative yep_ex-mb-2',
        disabled ? 'yep_ex-cursor-default' : 'yep_ex-cursor-pointer'
      )}
    >
      <input
        id={id}
        type="radio"
        name={name}
        value={value.toString()}
        checked={checked}
        onChange={handleChange}
        className="yep_ex-invisible yep_ex-absolute"
        disabled={disabled}
      />

      <div className="yep_ex-flex yep_ex-items-center">
        <div className="yep_ex-rounded-full yep_ex-border yep_ex-border-neutral-200 yep_ex-p-1 yep_ex-mr-2">
          <div
            className={clsx(
              'yep_ex-rounded yep_ex-w-2 yep_ex-h-2 yep-',
              !disabled && checked
                ? 'yep_ex-bg-neutral-600'
                : 'yep_ex-bg-transparent',
              disabled && 'yep_ex-bg-neutral-200'
            )}
          ></div>
        </div>
        <div
          className={clsx(
            'yep_ex-text-sm',
            disabled && 'yep_ex-text-neutral-300'
          )}
        >
          {label}
        </div>
      </div>
    </label>
  );
};
