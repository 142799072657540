import axios from 'axios';
import { EMAIL_VALIDATION_URL } from 'shared/config';

const baseURL = EMAIL_VALIDATION_URL;

const getHeaders = (token: string | null) => {
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8'
  };

  if (!token) return headers;
  return {
    ...headers,
    Authorization: `Bearer ${token}`
  };
};

const api = axios.create({
  baseURL,
  timeout: 30000
});

export const emailValidationService = {
  get: async (url: string, token: string | null = null) => {
    const { data } = await api.get(url, {
      headers: getHeaders(token)
    });
    return data;
  },
  post: async (url: string, payload: any, token: string | null = null) => {
    const { data } = await api.post(url, JSON.stringify(payload), {
      headers: getHeaders(token)
    });
    return data;
  },
  patch: async (url: string, payload: any, token: string | null = null) => {
    const { data } = await api.patch(url, JSON.stringify(payload), {
      headers: getHeaders(token)
    });
    return data;
  },
  delete: async (url: string, token: string | null = null) => {
    const { data } = await api.delete(url, {
      headers: getHeaders(token)
    });
    return data;
  }
};
