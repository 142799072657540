import { FiVolume1, FiVolume2, FiVolumeX } from 'react-icons/fi';

type VolumeIconProps = {
  currentVolume: number;
};

export const VolumeIcon = ({ currentVolume }: VolumeIconProps) => {
  const className = 'text-xl';
  if (currentVolume === 0) {
    return <FiVolumeX className={className} />;
  } else if (currentVolume > 0 && currentVolume < 0.5) {
    return <FiVolume1 className={className} />;
  } else {
    return <FiVolume2 className={className} />;
  }
};
