import React, { Fragment, useCallback, ChangeEvent } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Loader, OutlineButton, PrimaryButton, featureFlags } from 'shared';
import { videoStore } from 'entities/video';
import { avatarStore } from 'entities/avatar';
import { useGenerateVideoModel } from '../model';
import { AiOutlineClose } from 'react-icons/ai';
import { creditStore } from 'entities/credit';

type GenerateVideoProps = {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  loading: boolean;
  estimate: number | null;
};

export const GenerateVideoPopup = ({
  opened,
  setOpened,
  loading,
  estimate
}: GenerateVideoProps) => {
  const { videoTitle, backgroundColor, setVideoTitle, parentId } = videoStore();
  const { credits } = creditStore();

  const { currentAvatar, isCropped } = avatarStore();

  const { generateVideo, isGenerating } = useGenerateVideoModel();

  const handleTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setVideoTitle(e.target.value);
  }, []);

  return (
    <Transition.Root show={opened} as={Fragment}>
      <Dialog
        as="div"
        className="yep_ex-relative yep_ex-z-50"
        onClose={() => setOpened(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="yep_ex-ease-out yep_ex-duration-300"
          enterFrom="yep_ex-opacity-0"
          enterTo="yep_ex-opacity-100"
          leave="yep_ex-ease-in yep_ex-duration-200"
          leaveFrom="yep_ex-opacity-100"
          leaveTo="yep_ex-opacity-0"
        >
          <div className="yep_ex-fixed yep_ex-inset-0 yep_ex-bg-neutral-900 yep_ex-bg-opacity-70 yep_ex-transition-opacity" />
        </Transition.Child>

        <div className="yep_ex-fixed yep_ex-inset-4 yep_ex-z-10 yep_ex-overflow-y-auto">
          <div className="yep_ex-flex yep_ex-min-h-full yep_ex-items-end yep_ex-justify-center yep_ex-text-center sm:yep_ex-items-center sm:yep_ex-p-0">
            <Transition.Child
              as={Fragment}
              enter="yep_ex-ease-out yep_ex-duration-300"
              enterFrom="yep_ex-opacity-0 yep_ex-translate-y-4 sm:yep_ex-translate-y-0 sm:yep_ex-scale-95"
              enterTo="yep_ex-opacity-100 yep_ex-translate-y-0 sm:yep_ex-scale-100"
              leave="yep_ex-ease-in yep_ex-duration-200"
              leaveFrom="yep_ex-opacity-100 yep_ex-translate-y-0 sm:yep_ex-scale-100"
              leaveTo="yep_ex-opacity-0 yep_ex-translate-y-4 sm:yep_ex-translate-y-0 sm:yep_ex-scale-95"
            >
              <Dialog.Panel className="yep_ex-relative yep_ex-transform yep_ex-overflow-hidden yep_ex-rounded-lg yep_ex-bg-white yep_ex-text-left yep_ex-shadow-xl yep_ex-transition-all sm:yep_ex-my-8 sm:yep_ex-w-full sm:yep_ex-max-w-[400px] yep_ex-flex yep_ex-flex-col">
                <button
                  onClick={() => setOpened(false)}
                  className="yep_ex-absolute yep_ex-top-4 yep_ex-right-4 yep_ex-z-50"
                >
                  <AiOutlineClose className="yep_ex-text-xl" />
                </button>
                <h3 className="yep_ex-text-center yep_ex-text-lg yep_ex-font-inter yep_ex-font-semibold yep_ex-text-gray-900 yep_ex-py-5">
                  Generate this video
                </h3>
                <hr />
                <div className="yep_ex-p-6">
                  <div
                    className="yep_ex-mb-4 yep_ex-h-[25vh] yep_ex-rounded-lg yep_ex-overflow-hidden yep_ex-bg-neutral-100 h-sm:hidden"
                    style={{
                      backgroundColor
                    }}
                  >
                    <img
                      src={
                        currentAvatar?.inputImageUrl ??
                        (isCropped
                          ? currentAvatar?.menuCircleImageUrl
                          : currentAvatar?.menuImageUrl) ??
                        ''
                      }
                      alt="generate-video-preview"
                      className="yep_ex-w-full yep_ex-h-full yep_ex-object-contain"
                    />
                  </div>
                  <div className="yep_ex-flex yep_ex-items-center yep_ex-mb-4 yep_ex-text-sm">
                    <div className="yep_ex-w-1/2">
                      <p className="yep_ex-text-gray-700 yep_ex-font-medium">
                        Cost
                      </p>
                      <p className="yep_ex-text-neutral-900 yep_ex-font-semibold">
                        {estimate?.toFixed(2)}
                      </p>
                    </div>
                    <div>
                      {loading ? (
                        <Loader size={20} isButton />
                      ) : (
                        <>
                          <p className="yep_ex-text-gray-700 yep_ex-font-medium">
                            Credits left
                          </p>
                          <p className="yep_ex-text-neutral-900 yep_ex-font-semibold">
                            {credits.toFixed(2)} left
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <label
                    className="yep_ex-block yep_ex-text-sm yep_ex-font-medium yep_ex-text-neutral-700 yep_ex-mb-1.5"
                    htmlFor="videoTitle"
                  >
                    Title
                  </label>
                  <input
                    className="yep_ex-appearance-none yep_ex-block yep_ex-w-full yep_ex-px-3.5 yep_ex-py-2.5 yep_ex-border yep_ex-border-neutral-300 yep_ex-rounded-md yep_ex-shadow-sm yep_ex-text-neutral-500 yep_ex-focus:outline-none yep_ex-focus:ring-secondary yep_ex-focus:border-secondary yep_ex-sm:text-sm"
                    placeholder="Add video name"
                    value={videoTitle}
                    onChange={handleTitleChange}
                  />
                </div>
                <hr />
                <div className="yep_ex-flex yep_ex-justify-center yep_ex-items-center yep_ex-gap-3 yep_ex-p-5">
                  <OutlineButton
                    className="yep_ex-w-1/2 focus:yep_ex-outline-primary-600"
                    onClick={() => setOpened(false)}
                  >
                    Cancel
                  </OutlineButton>
                  <PrimaryButton
                    type="button"
                    className="yep_ex-w-1/2 focus:yep_ex-outline-white yep_ex-flex yep_ex-justify-center yep_ex-items-center"
                    onClick={() => {
                      if (parentId) {
                        generateVideo(setOpened);
                      }
                    }}
                  >
                    {isGenerating ? <Loader size={20} isButton /> : 'Generate'}
                  </PrimaryButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
