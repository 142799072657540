import axios, { AxiosProgressEvent } from 'axios';
import { v4 as uuidv4 } from 'uuid';

const baseURL =
  'https://storage.googleapis.com/upload/storage/v1/b/yepicai-backend.appspot.com/o?uploadType=media&name=';

const http = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json'
  }
});

const getFileName = (file: File) => {
  return uuidv4() + '.' + file.name.split('.').pop();
};

export const fileApi = {
  upload: async (file: File) => {
    const { data } = await http.post(baseURL + getFileName(file), file, {
      headers: {
        Accept: 'application/json',
        'Content-Type': file.type
      }
    });
    return data;
  },
  uploadWithProgress: async (
    file: File,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void
  ) => {
    const { data } = await http.post('/' + getFileName(file), file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress
    });
    return data;
  }
};
