import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { videoStore } from 'entities/video';
import { Voiceover } from 'entities/voiceover';
import { AddVoiceover } from 'features/add-voiceover';
import { Modal } from 'shared';

export const VoiceoverWidget = () => {
  const { voiceover } = videoStore();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="yep_ex-flex yep_ex-items-center yep_ex-gap-2 yep_ex-text-primary-600 yep_ex-mb-4"
      >
        <AiOutlinePlus className="yep_ex-text-xl" />
        Add voiceover
      </button>
      {voiceover && <Voiceover />}
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={setIsModalOpen}
          title={'Some title'}
          noPadding
          widthClasses=" sm:yep_ex-w-auto"
        >
          <AddVoiceover onClose={() => setIsModalOpen(false)} />
        </Modal>
      )}
    </>
  );
};
