import { create } from 'zustand';
import { fabric } from 'fabric';
import { FormatOptions, LOGOUT_RESET } from 'shared';
import { createJSONStorage, persist } from 'zustand/middleware';

type CanvasState = {
  canvas: fabric.Canvas | null;
  currentSize: unknown;
  currentFormat: FormatOptions;
};

type CanvasActions = {
  setCanvas: (canvas: fabric.Canvas) => void;
  setCurrentSize: (size: unknown) => void;
  setFormat: (newFormat: FormatOptions) => void;
  resetStore: () => void;
};

export type CanvasStore = CanvasState & CanvasActions;

window.addEventListener(LOGOUT_RESET, () => {
  canvasStore.getState().resetStore();
});

export const canvasStore = create(
  persist<CanvasStore>(
    (set, get) => ({
      canvas: null,
      currentSize: null,
      currentFormat: 1,
      setCanvas: (canvas) => set({ canvas }),
      setCurrentSize: (size: unknown) => set({ currentSize: size }),
      setFormat: (newFormat) => set({ currentFormat: newFormat }),
      resetStore: () => {
        set({
          canvas: null,
          currentSize: null,
          currentFormat: 1
        });
      }
    }),
    {
      name: 'canvas-store',
      storage: createJSONStorage(() => localStorage)
    }
  )
);
