import { Divider, featureFlags, SearchInput } from 'shared';
import { AvatarsList, avatarStore } from 'entities/avatar';
import { AvatarFilters, SelectedFilters } from 'features/filter-avatars';
import { SwitchBodyMotion } from 'features/switch-body-motion';
import { Tab } from 'shared/ui/Tab';
import { useEffect, useState } from 'react';

type AvatarTab = 'Avatars' | 'Talking Photos';

export const Avatars = () => {
  const {
    filteredPublicAvatars,
    filteredCustomTalkingPhotos,
    typeFilters,
    genderFilters,
    filterAvatars,
    resetTypeFilter
  } = avatarStore();

  const [activeTab, setActiveTab] = useState<AvatarTab>('Avatars');
  const [searchTerm, setSearchTerm] = useState('');

  const handleChangeTab = (tab: AvatarTab) => {
    setActiveTab(tab);
    if (tab === 'Avatars') {
      resetTypeFilter();
    }
  };

  useEffect(() => {
    filterAvatars(searchTerm);
  }, [searchTerm]);

  return (
    <>
      <div className="yep_ex-px-0 sm:yep_ex-px-4 yep_ex-flex yep_ex-space-x-0 yep_ex-mb-0 sm:yep_ex-mb-2">
        <Tab
          title="Avatars"
          isActive={activeTab === 'Avatars'}
          onClick={() => handleChangeTab('Avatars')}
        />
        <Tab
          title="Talking Photos"
          isActive={activeTab === 'Talking Photos'}
          onClick={() => handleChangeTab('Talking Photos')}
        />
      </div>
      <div className="yep_ex-px-4 yep_ex-py-2 yep_ex-flex yep_ex-justify-between yep_ex-gap-2 yep_ex-z-10">
        <SearchInput
          placeholder="Search by name"
          searchTerm={searchTerm}
          onSearch={(value) => setSearchTerm(value)}
        />
        <AvatarFilters activeTab={activeTab} />
      </div>
      {(genderFilters.length > 0 || typeFilters.length > 0) && (
        <SelectedFilters />
      )}
      <Divider className="yep_ex-mb-0 md:yep_ex-hidden" />
      {featureFlags.dynamicAvatars && <SwitchBodyMotion />}
      <div className="yep_ex-px-4 yep_ex-overflow-auto yep_ex-no-scrollbar yep_ex-w-full md:yep_ex-z-0">
        {activeTab === 'Avatars' && filteredPublicAvatars.length === 0 && (
          <p className="yep_ex-text-gray-600 yep_ex-text-[14px]">
            No results found.
          </p>
        )}

        {activeTab === 'Talking Photos' &&
          (typeFilters.length === 0 ||
            typeFilters.length === 2 ||
            (typeFilters.length === 1 &&
              typeFilters.includes('my avatars'))) && (
            <AvatarsList
              avatars={filteredCustomTalkingPhotos}
              title="My Talking Photos"
              isCustomTalkingPhoto
            />
          )}

        {(typeFilters.length === 0 ||
          typeFilters.length === 2 ||
          (typeFilters.length === 1 && typeFilters.includes('stock'))) && (
          <AvatarsList avatars={filteredPublicAvatars} title="Stock" />
        )}
      </div>
    </>
  );
};
