import { JoinWaitlistRequest, emailValidationService } from 'shared';

export const waitlistApi = {
  joinWaitlist: async (data: JoinWaitlistRequest) => {
    return await emailValidationService.post(
      '/v1/user-com/feature-request',
      data
    );
  }
};
