import { create } from 'zustand';
import { IMessage } from 'shared';
import { v4 as uuid } from 'uuid';

type ChatState = {
  initialMessages: IMessage[];
  messages: IMessage[];
  chunkMessage: IMessage | null;
  isChatGPTOpen: boolean;
};

export type ModifyScriptOptions =
  | 'shorter'
  | 'longer'
  | 'professional'
  | 'casual';

type ChatActions = {
  addMessage: (msg: IPartialMessage) => void;
  addMessageChunk: (chunk: string) => void;
  reset: () => void;
  clearChunkMessage: () => void;
  toggleChatGPT: (isOpen: boolean) => void;
};

interface IPartialMessage {
  id?: string;
  role: 'user' | 'assistant';
  content: string;
  hidden?: boolean;
}

export type ChatStore = ChatState & ChatActions;

export const chatStore = create<ChatStore>((set) => ({
  initialMessages: [
    {
      id: 'welcome',
      role: 'assistant',
      content: 'Hi, I’m here to help you write or update your script.',
      hidden: false
    },
    {
      id: 'question',
      role: 'assistant',
      content: 'What do you want me to do?',
      hidden: false
    }
  ],
  messages: [],
  isChatGPTOpen: false,
  toggleChatGPT: (isOpen) => {
    set({ isChatGPTOpen: isOpen });
  },
  chunkMessage: null,
  addMessage: ({ id = uuid(), hidden = false, ...msg }: IPartialMessage) => {
    set((state) => ({ messages: [...state.messages, { id, hidden, ...msg }] }));
  },
  addMessageChunk: (chunk: string) => {
    set((state) => ({
      chunkMessage: {
        ...(state.chunkMessage ?? {
          role: 'assistant',

          content: '',
          hidden: false,
          id: `assistant-${Date.now()}`
        }),
        content: (state.chunkMessage?.content || '') + chunk
      }
    }));
  },
  clearChunkMessage: () => {
    set({
      chunkMessage: null
    });
  },
  reset: () => {
    set({ messages: [], chunkMessage: null });
  }
}));
