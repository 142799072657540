import { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { GrClose } from 'react-icons/gr';
import { appStore } from 'app/store';

const RegenerateModal: FC<{ show: boolean; closeModal: () => void }> = ({
  show,
  closeModal
}) => {
  const { regenerateApiKey } = appStore();
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="yep_ex-relative yep_ex-z-10"
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="yep_ex-ease-out yep_ex-duration-300"
          enterFrom="yep_ex-opacity-0"
          enterTo="yep_ex-opacity-100"
          leave="yep_ex-ease-in yep_ex-duration-200"
          leaveFrom="yep_ex-opacity-100"
          leaveTo="yep_ex-opacity-0"
        >
          <div className="yep_ex-fixed yep_ex-inset-0 yep_ex-bg-[#171717]/60 yep_ex-transition-opacity yep_ex-backdrop-blur-sm" />
        </Transition.Child>

        <div className="yep_ex-fixed yep_ex-inset-0 yep_ex-z-10 yep_ex-overflow-y-auto">
          <div className="yep_ex-flex yep_ex-min-h-full yep_ex-justify-center yep_ex-p-4 yep_ex-text-center yep_ex-items-start sm:yep_ex-items-center max-sm:yep_ex-pt-8 sm:yep_ex-p-0">
            <Transition.Child
              as={Fragment}
              enter="yep_ex-ease-out yep_ex-duration-300"
              enterFrom="yep_ex-opacity-0 yep_ex-translate-y-4 sm:yep_ex-translate-y-0 sm:yep_ex-scale-95"
              enterTo="yep_ex-opacity-100 yep_ex-translate-y-0 sm:yep_ex-scale-100"
              leave="yep_ex-ease-in yep_ex-duration-200"
              leaveFrom="yep_ex-opacity-100 yep_ex-translate-y-0 sm:yep_ex-scale-100"
              leaveTo="yep_ex-opacity-0 yep_ex-translate-y-4 sm:yep_ex-translate-y-0 sm:yep_ex-scale-95"
            >
              <Dialog.Panel className="yep_ex-relative yep_ex-transform yep_ex-overflow-hidden yep_ex-rounded-lg yep_ex-bg-white yep_ex-text-left yep_ex-shadow-xl yep_ex-transition-all yep_ex-my-4 sm:yep_ex-w-[25rem]">
                <div className="yep_ex-p-6">
                  <div className="yep_ex-flex yep_ex-justify-between yep_ex-items-center">
                    <h2 className="yep_ex-text-lg yep_ex-font-semibold yep_ex-text-neutral-900">
                      Regenerate API key
                    </h2>
                    <button
                      className="yep_ex--mt-2.5 yep_ex--mr-2.5 yep_ex-p-2.5 yep_ex-rounded-lg"
                      onClick={closeModal}
                    >
                      <GrClose className="yep_ex-w-6 yep_ex-h-6 yep_ex-text-neutral-500" />
                    </button>
                  </div>
                  <span className="yep_ex-mt-1 yep_ex-text-neutral-600 yep_ex-text-sm">
                    Any applications or scripts using this API key will no
                    longer be able to access the Yepic API.
                  </span>
                </div>
                <div className="yep_ex-flex yep_ex-w-full yep_ex-p-6 yep_ex-gap-2">
                  <button
                    className="yep_ex-w-full yep_ex-border yep_ex-border-neutral-300 yep_ex-bg-white yep_ex-rounded-md yep_ex-text-neutral-700 yep_ex-py-2.5"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="yep_ex-w-full yep_ex-border yep_ex-border-primary-600 yep_ex-bg-primary-600 yep_ex-rounded-md yep_ex-text-white yep_ex-py-2.5"
                    onClick={() => regenerateApiKey().then(closeModal)}
                  >
                    Confirm
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RegenerateModal;
