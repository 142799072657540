import { IMessage, OutlineButton, PrimaryButton } from 'shared';
import { FC } from 'react';
import { avatarStore } from 'entities/avatar';
import { videoStore } from 'entities/video';
import { ModifyScriptOptions } from 'entities/chatgpt';
import { ThreeDots } from 'react-loader-spinner';

interface ChatMessageProps extends IMessage {
  isPrevSameRole: boolean;
  onClose: () => void;
  onSendMessage: (content: string, history?: boolean) => Promise<void>;
  isLoading: boolean;
  onChunkTextSelect: () => void;
}

export const ChatAssistantMessage: FC<ChatMessageProps> = ({
  onSendMessage,
  onClose,
  content,
  isPrevSameRole,
  isLoading,
  onChunkTextSelect
}) => {
  const currentAvatar = avatarStore((state) => state.currentAvatar);
  const setScriptText = videoStore((state) => state.setScriptText);

  const handleApplyScript = (content: string) => {
    setScriptText(content);
    onClose();
  };

  if (!content && isLoading)
    return (
      <div className="yep_ex-flex yep_ex-gap-4" onSelect={onChunkTextSelect}>
        <div className="yep_ex-min-w-[2.5rem] yep_ex-w-10 yep_ex-h-10 yep_ex-rounded-full yep_ex-overflow-hidden">
          {!isPrevSameRole && currentAvatar && (
            <img
              src={
                currentAvatar?.menuImageUrl ??
                currentAvatar?.inputImageUrl ??
                currentAvatar?.rawImageUrl ??
                ''
              }
              className="yep_ex-size-full yep_ex-object-cover"
              alt="avatar"
            />
          )}
        </div>
        <div className="yep_ex-rounded-lg yep_ex-bg-neutral-50 yep_ex-px-4 yep_ex-py-2 yep_ex-text-sm yep_ex-text-neutral-600 yep_ex-mr-14">
          <ThreeDots
            height="24"
            width="24"
            radius="2"
            color="#000000"
            visible
          />
        </div>
      </div>
    );

  const [assistantMessage, script] = content.trim().split(/\n*===\n*/gm);
  const handleModifyScript = (modifier: ModifyScriptOptions) => async () => {
    await onSendMessage(`Make this text ${modifier}: ${script.trim()}`, false);
  };
  return (
    <>
      {!!assistantMessage?.trim() && (
        <div className="yep_ex-flex yep_ex-gap-4">
          <div className="yep_ex-min-w-[2.5rem] yep_ex-w-10 yep_ex-h-10 yep_ex-rounded-full yep_ex-overflow-hidden">
            {!isPrevSameRole && currentAvatar && (
              <img
                src={
                  currentAvatar?.menuImageUrl ??
                  currentAvatar?.inputImageUrl ??
                  currentAvatar?.rawImageUrl ??
                  ''
                }
                alt="avatar"
              />
            )}
          </div>
          <div
            className="yep_ex-rounded-lg yep_ex-bg-neutral-50 yep_ex-px-4 yep_ex-py-2 yep_ex-text-sm yep_ex-text-neutral-600 yep_ex-mr-14"
            onSelect={onChunkTextSelect}
          >
            {assistantMessage.trim()}
          </div>
        </div>
      )}
      {!!script?.trim() && (
        <div className="yep_ex-flex yep_ex-gap-4">
          <div className="yep_ex-min-w-[2.5rem] yep_ex-w-10 yep_ex-h-10 yep_ex-rounded-full yep_ex-overflow-hidden" />
          <div className="yep_ex-rounded-lg yep_ex-px-4 yep_ex-py-2 yep_ex-text-sm yep_ex-text-neutral-600 yep_ex-mr-14 yep_ex-flex yep_ex-flex-col yep_ex-gap-4 yep_ex-border yep_ex-border-primary-600">
            <div
              className="yep_ex-whitespace-pre-wrap"
              onSelect={onChunkTextSelect}
            >
              {script}
            </div>
            <div className="yep_ex-flex yep_ex-gap-2 yep_ex-flex-wrap">
              <PrimaryButton
                onClick={() => handleApplyScript(script.trim())}
                className="!yep_ex-py-2 !yep_ex-px-3.5"
              >
                Apply script
              </PrimaryButton>
              <OutlineButton
                onClick={handleModifyScript('shorter')}
                disabled={isLoading}
                className="!yep_ex-py-2 !yep_ex-px-3.5"
              >
                Shorter
              </OutlineButton>
              <OutlineButton
                onClick={handleModifyScript('longer')}
                disabled={isLoading}
                className="!yep_ex-py-2 !yep_ex-px-3.5"
              >
                Longer
              </OutlineButton>
              <OutlineButton
                onClick={handleModifyScript('professional')}
                disabled={isLoading}
                className="!yep_ex-py-2 !yep_ex-px-3.5"
              >
                More professional
              </OutlineButton>
              <OutlineButton
                onClick={handleModifyScript('casual')}
                disabled={isLoading}
                className="!yep_ex-py-2 !yep_ex-px-3.5"
              >
                More casual
              </OutlineButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
