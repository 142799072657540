import { httpService, ICreditCalculatorPayload } from 'shared';

export const creditApi = {
  fetchCredits: async () => {
    return await httpService.get<{
      balance: number;
    }>('/creditledger');
  },
  estimateCredits: async (payload: ICreditCalculatorPayload) => {
    return await httpService.post('/creditcalculator/estimate', payload);
  }
};
