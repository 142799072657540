import clsx from 'clsx';
import { avatarStore } from 'entities/avatar';
import { RadioButton } from 'shared';

export const SwitchBodyMotion = () => {
  const { currentAvatar, isDynamic, setIsDynamic } = avatarStore();

  return (
    <div className="yep_ex-px-4 yep_ex-mb-4">
      <h3
        className={clsx(
          'yep_ex-text-sm yep_ex-font-semibold yep_ex-mb-2',
          currentAvatar?.talkingPhotoUpperBody
            ? 'yep_ex-text-black'
            : 'yep_ex-text-neutral-300'
        )}
      >
        Body motion
      </h3>
      <RadioButton
        id="dynamic"
        value={isDynamic}
        label={'Dynamic'}
        name="isDynamic"
        checked={isDynamic}
        onChange={() => setIsDynamic(true)}
        disabled={!currentAvatar?.talkingPhotoUpperBody ?? true}
      />
      <RadioButton
        id="static"
        value={isDynamic}
        label={'Static'}
        name="isDynamic"
        checked={!isDynamic}
        onChange={() => setIsDynamic(false)}
        disabled={!currentAvatar?.talkingPhotoUpperBody ?? true}
      />
    </div>
  );
};
