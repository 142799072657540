import { FC, useEffect, useRef, useState } from 'react';
import { ChatMessage } from './ChatMessage';
import { chatStore } from 'entities/chatgpt';
import { IMessage } from 'shared';

export const ChatHistory: FC<{
  onClose: () => void;
  onSendMessage: (content: string, history?: boolean) => Promise<void>;
  isLoading: boolean;
}> = ({ onClose, isLoading, onSendMessage }) => {
  const { messages, initialMessages, chunkMessage } = chatStore();
  const [allowScrollDown, setAllowScrollDown] = useState(true);
  const ref = useRef<HTMLDivElement | null>(null);

  const filteredMessage: IMessage[] = [
    ...initialMessages,
    ...messages,
    ...(chunkMessage ? [chunkMessage] : [])
  ].filter((msg) => !msg.hidden);

  const messageLength = useRef(filteredMessage.length);

  useEffect(() => {
    if (ref.current) ref.current.scrollTo(0, ref.current.scrollHeight);
  }, []);

  useEffect(() => {
    if (
      allowScrollDown &&
      ref.current &&
      messageLength.current !== filteredMessage.length
    )
      ref.current.scrollTo(0, ref.current.scrollHeight);
  }, [messages, chunkMessage, allowScrollDown]);

  useEffect(() => {
    setAllowScrollDown(true);
  }, [messages]);

  return (
    <div
      className="yep_ex-px-4 md:yep_ex-px-6 md:yep_ex-pb-5 max-md:yep_ex-basis-full md:yep_ex-h-[27.85rem] yep_ex-overflow-y-auto no-scrollbar yep_ex-gap-3 yep_ex-flex yep_ex-flex-col"
      ref={ref}
      onScroll={(e) => {
        if (
          chunkMessage &&
          e.currentTarget.scrollHeight !==
            e.currentTarget.scrollTop + e.currentTarget.clientHeight
        ) {
          setAllowScrollDown(false);
        }
      }}
    >
      <div className="yep_ex-pt-2" />
      {filteredMessage.map((m, inx, arr) => (
        <ChatMessage
          key={m.id}
          {...m}
          isPrevSameRole={arr[inx - 1]?.role === m.role}
          onClose={onClose}
          isLoading={isLoading}
          onSendMessage={onSendMessage}
          onChunkTextSelect={() =>
            m === chunkMessage && setAllowScrollDown(false)
          }
        />
      ))}
    </div>
  );
};
