import clsx from 'clsx';
import { videoStore } from 'entities/video';
import {
  Divider,
  OutlineButton,
  PrimaryButton,
  StyleOptions,
  capitalize
} from 'shared';

const styles: StyleOptions[] = ['friendly', 'professional', 'cheeky', 'happy'];

export const Style = () => {
  const { currentStyle, setStyle } = videoStore();

  return (
    <>
      {/* <div className="px-4">
        <PrimaryButton
          className="w-full py-2.5 font-semibold"
          disabled
          isSecondary
          // onClick={} // todo: Add create style feature
        >
          Create style
        </PrimaryButton>
        <Divider className="my-6" />
      </div> */}
      <div className="yep_ex-px-4">
        <Divider />
        <div className="yep_ex-flex yep_ex-flex-col yep_ex-gap-y-4">
          {styles.map((style) => (
            <OutlineButton
              key={`${style}-style`}
              className="hover:yep_ex-border-primary-600 yep_ex-flex yep_ex-items-center yep_ex-gap-2 yep_ex-group"
              active={currentStyle === style}
              onClick={() => setStyle(style)}
            >
              <span
                className={clsx(
                  'group-hover:yep_ex-text-primary-600',
                  currentStyle === style && 'yep_ex-text-primary-600'
                )}
              >
                {capitalize(style)}
              </span>
            </OutlineButton>
          ))}
        </div>
      </div>
    </>
  );
};
