import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Loader } from './Loader';

interface PrimaryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  isLoading?: boolean;
  loaderSize?: number;
  isSecondary?: boolean;
}

export const PrimaryButton = ({
  children,
  className,
  onClick,
  disabled,
  isLoading,
  loaderSize,
  isSecondary,
  ...props
}: PropsWithChildren<PrimaryButtonProps>) => {
  return (
    <button
      className={clsx(
        'yep_ex-rounded-md yep_ex-text-sm/5 yep_ex-font-medium yep_ex-px-4 yep_ex-py-2.5 yep_ex-border',
        !isSecondary &&
          !disabled &&
          'yep_ex-bg-primary-600 yep_ex-text-white yep_ex-border-primary-600 hover:yep_ex-bg-primary-700 hover:yep_ex-border-primary-700',
        isSecondary &&
          !disabled &&
          'yep_ex-bg-primary-50 yep_ex-text-primary-600 yep_ex-border-primary-50 hover:yep_ex-bg-primary-100 hover:yep_ex-border-primary-100',
        disabled && 'yep_ex-cursor-default',
        disabled &&
          !isSecondary &&
          'yep_ex-bg-primary-300 yep_ex-text-primary-100 yep_ex-border-primary-300',
        disabled &&
          isSecondary &&
          'yep_ex-bg-primary-50 yep_ex-text-primary-200 yep_ex-border-primary-50',
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading ? <Loader size={loaderSize} isButton /> : children}
    </button>
  );
};
