import { useState } from 'react';
import RegenerateModal from './RegenerateModal';

const RegenerateBtn = () => {
  const [isRegenerateModal, setIsRegenerateModal] = useState(false);
  return (
    <>
      <button
        className="yep_ex-text-sm yep_ex-rounded-md yep_ex-h-fit yep_ex-py-2.5 yep_ex-flex yep_ex-gap-2 yep_ex-text-left yep_ex-text-primary-700"
        onClick={() => setIsRegenerateModal(true)}
      >
        <svg
          className="yep_ex-min-w-[1.25rem] yep_ex-min-h-[1.25rem] yep_ex-w-5 yep_ex-h-5"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0442 10.7439C16.8127 12.9188 15.5804 14.9569 13.5412 16.1342C10.1533 18.0902 5.8212 16.9295 3.86519 13.5415L3.65686 13.1807M2.95515 9.25553C3.18665 7.08062 4.41898 5.04252 6.45813 3.86521C9.84604 1.9092 14.1781 3.06998 16.1341 6.45789L16.3425 6.81873M2.91101 15.0547L3.52105 12.778L5.79776 13.3881M14.202 6.61137L16.4787 7.22141L17.0888 4.9447"
            stroke="#B015FF"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Regenerate key</span>
      </button>
      <RegenerateModal
        show={isRegenerateModal}
        closeModal={() => setIsRegenerateModal(false)}
      />
      <RegenerateModal
        show={isRegenerateModal}
        closeModal={() => setIsRegenerateModal(false)}
      />
      <RegenerateModal
        show={isRegenerateModal}
        closeModal={() => setIsRegenerateModal(false)}
      />
    </>
  );
};

export default RegenerateBtn;
