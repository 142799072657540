import { Voice, voiceStore } from 'entities/voice';
import { VoicesFilters } from 'features/filter-voices';
import { WaitlistForm } from 'features/join-waitlist';
import { usePlayAudio } from 'features/play-audio';
import { useState } from 'react';

export const Voices = () => {
  const { filteredVoices } = voiceStore();
  const { isPlaying, currentAudio, handlePlayAudio, handlePauseAudio } =
    usePlayAudio();

  const [showCustomVoiceModal, setShowCustomVoiceModal] = useState(false);

  return (
    <>
      <VoicesFilters />
      <div className="yep_ex-px-4 yep_ex-w-full yep_ex-flex yep_ex-flex-col yep_ex-gap-y-2 yep_ex-overflow-y-auto yep_ex-h-full yep_ex-py-3 md:yep_ex-pt-0">
        {filteredVoices &&
          filteredVoices.length > 0 &&
          filteredVoices.map((voice) => (
            <Voice
              key={voice.id}
              voice={voice}
              onPlay={handlePlayAudio}
              onPause={handlePauseAudio}
              isPlaying={isPlaying}
              currentAudio={currentAudio}
            />
          ))}
      </div>

      {showCustomVoiceModal && (
        <WaitlistForm
          onClose={() => setShowCustomVoiceModal(false)}
          featureName="custom_voice"
          subtitle="Fill the form below to get access to custom voices. Our team will contact you shortly."
          label="How would you use custom voices?"
          placeholder="I want to use custom voices for..."
        />
      )}
    </>
  );
};
