import { IVoice, Language, countries } from 'shared';

export const getVoiceData = (voices: IVoice[]) => {
  const voicesByLang: IVoice[] = [];

  for (const voice of voices) {
    if (!voicesByLang.some((v) => v.languageCode === voice.languageCode)) {
      voicesByLang.push(voice);
    }
  }

  let languages: Language[] = voicesByLang.map((v: any) => {
    const countryCode: keyof typeof countries = v.languageCode.slice(3);
    const country = countries[countryCode];

    return {
      name: v.language,
      country
    };
  });
  const allLangs = { name: 'All languages', country: null };
  const multilingual = {
    name: 'Multilingual',
    country: { name: 'Multilingual', emoji: '🌎' }
  };
  const engLangs = languages
    .filter((el) => el.name.includes('English'))
    .reverse();
  const nonEngLangs = languages?.filter((el) => !el.name.includes('English'));

  languages = [allLangs, multilingual, ...engLangs, ...nonEngLangs];

  const voiceProviders: string[] = [
    ...new Set([...voices.map((v: any) => v.provider)])
  ].sort();

  voiceProviders.unshift('All providers');

  return [voices, voiceProviders, languages] as const;
};
