import MobileTabsGroup from 'pages/editor/ui/MobileTabsGroup';
import React, { useState } from 'react';
import { NameT, tabs } from 'features/navigation';
import { Avatars } from 'widgets/Avatars';
import { Developer } from 'widgets/Developers';
import { Style } from 'widgets/Style';
import { Voices } from 'widgets/Voices';
import { MobileScriptWrapper } from './MobileScriptWrapper';
import clsx from 'clsx';

export const MobileMenu = () => {
  const [activeTab, setActiveTab] = useState<NameT>(tabs[0].name);

  const getContentForActiveTab = () => {
    switch (activeTab) {
      case 'script':
        return <MobileScriptWrapper />;
      case 'avatar':
        return <Avatars />;
      case 'voice':
        return <Voices />;
      case 'style':
        return <Style />;
      case 'developers':
        return <Developer />;
      default:
        return null;
    }
  };
  return (
    <div
      className={clsx([
        'yep_ex-min-h-[13rem] md:yep_ex-hidden yep_ex-flex-col yep_ex-flex-1 yep_ex-overflow-hidden yep_ex-relative yep_ex-grid yep_ex-grid-rows-[1fr,auto] yep_ex-overflow-y-auto',
        activeTab === 'developers' && 'yep_ex-pt-8'
      ])}
    >
      <div className="yep_ex-flex yep_ex-flex-col yep_ex-w-full yep_ex-overflow-y-auto yep_ex-relative">
        {getContentForActiveTab()}
      </div>
      <MobileTabsGroup
        tabs={tabs}
        activeTab={activeTab}
        toggleTab={setActiveTab}
      />
    </div>
  );
};
