import { creditStore } from 'entities/credit';

export const Credits = () => {
  const {
    toggleBuyCreditsModal,
    credits,
    planFeatures,
    toggleFeatureBlockModal
  } = creditStore();

  return (
    <div className="yep_ex-flex yep_ex-items-center">
      <div className="yep_ex-text-sm yep_ex-text-neutral-600 yep_ex-flex yep_ex-items-center yep_ex-justify-center">
        <div className="yep_ex-flex yep_ex-justify-end yep_ex-items-center">
          {credits.toFixed(2)}
        </div>
        <span className="yep_ex-block yep_ex-ml-1">Credits</span>
      </div>
      <button
        className="yep_ex-text-primary-700 yep_ex-font-semibold yep_ex-ml-2 yep_ex-text-sm"
        onClick={
          planFeatures?.extraCreditsAllowed
            ? toggleBuyCreditsModal
            : () => toggleFeatureBlockModal('extraCredits')
        }
      >
        Buy
      </button>
    </div>
  );
};
