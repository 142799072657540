import { fabric } from 'fabric';
import { useEffect } from 'react';
import { avatarStore } from 'entities/avatar';
import { canvasStore } from 'entities/canvas';

fabric.Object.NUM_FRACTION_DIGITS = 4;

export const useFabricCanvas = () => {
  const { canvas } = canvasStore();
  const { currentAvatar } = avatarStore();

  const addAvatar = (url: string) => {
    if (canvas) {
      if (canvas.getObjects().length > 0) {
        canvas.clear();
        canvas.backgroundColor = 'rgb(220,245,245)';
      }
      const options = {
        // top: 300,
        // left: 700,
        crossOrigin: 'Anonymous',
        borderColor: '#000000',
        cornerColor: '#000000'
      };

      fabric.Image.fromURL(
        url,
        (image) => {
          canvas.add(image);
          image.scaleX = 2;
          image.scaleY = 2;
          image.viewportCenter();

          canvas.renderAll();
        },
        options
      );
    }
  };

  useEffect(() => {
    if (currentAvatar && currentAvatar.menuImageUrl) {
      addAvatar(currentAvatar.menuImageUrl);
    }
  }, [currentAvatar]);
};
