import { AxiosError } from 'axios';

export const getCookieValue = (cookieName: string) => {
  const cookies = document.cookie.split(';');

  const targetCookie = cookies.find((cookie) => {
    const trimmedCookie = cookie.trim();
    return trimmedCookie.startsWith(`${cookieName}=`);
  });

  if (targetCookie) {
    return targetCookie.trim().substring(`${cookieName}=`.length);
  }

  return null; // If cookie is not found
};

export function setCookie(
  name: string,
  value: string,
  expiresTime?: Date | number | string
): void {
  let expires = '';
  if (expiresTime) {
    if (expiresTime instanceof Date) {
      expires = '; expires=' + expiresTime.toUTCString();
    }
    if (typeof expiresTime === 'string') {
      const date = new Date(expiresTime);
      expires = '; expires=' + date.toUTCString();
    }
    if (typeof expiresTime === 'number') {
      const date = new Date();
      date.setTime(date.getTime() + expiresTime * 1000);
      expires = '; expires=' + date.toUTCString();
    }
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function capitalize(str: string) {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
}

export const getErrorMessage = (
  err: unknown,
  fallbackMessage: string = '',
  showFallbackWError: boolean = false
) => {
  if (err instanceof AxiosError) {
    if (err.response && err.response.data) {
      const res = err.response.data;
      if ('message' in res) {
        return showFallbackWError
          ? fallbackMessage + ' Error: ' + res.message
          : res.message;
      }
      if ('title' in res) {
        return showFallbackWError
          ? fallbackMessage + ' Error: ' + res.title
          : res.message;
      }
    } else {
      return (
        fallbackMessage ||
        'An unexpected error occured. Please try again later.'
      );
    }
  }

  return (
    fallbackMessage || 'An unexpected error occured. Please try again later.'
  );
};

export const addZero = (n: number) => {
  if (n < 10) return `0${n}`;

  return n.toString();
};
