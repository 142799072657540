import React from 'react';
import ReactDOM from 'react-dom';

type TooltipPortalProps = {
  children: React.ReactNode;
  position: { top: number; left: number };
};

const TooltipPortal: React.FC<TooltipPortalProps> = ({
  children,
  position
}) => {
  return ReactDOM.createPortal(
    <div
      style={{
        position: 'absolute',
        top: position.top,
        left: position.left,
        zIndex: 100
      }}
      className="yep_ex-bg-neutral-900 yep_ex-text-white yep_ex-p-2 yep_ex-rounded-md yep_ex-whitespace-nowrap yep_ex-text-xs"
    >
      {children}
    </div>,
    document.body
  );
};

export default TooltipPortal;
