import { useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import { avatarStore } from 'entities/avatar';
import { videoStore } from 'entities/video';
import { voiceStore } from 'entities/voice';
import {
  VideoGenerationPayload,
  featureFlags,
  firebaseAnalytics,
  getErrorMessage,
  showError,
  showSuccessMessage,
  logger
} from 'shared';
import { generateVideoApi } from '../api';
import { creditStore } from 'entities/credit';
import { logEvent } from 'firebase/analytics';
import * as amplitude from '@amplitude/analytics-browser';

export const useGenerateVideoModel = () => {
  const {
    currentAvatar,
    isDynamic,
    setCurrentAvatar,
    isCropped,
    setIsCropped
  } = avatarStore();
  const { currentVoice, setCurrentVoice } = voiceStore();
  const {
    voiceover,
    scriptText,
    videoTitle,
    setScriptText,
    setVideoTitle,
    setVoiceover,
    backgroundColor,
    setBackgroundColor,
    setParentId,
    parentId
  } = videoStore();
  const fetchCredits = creditStore((state) => state.fetchCredits);
  const [isGenerating, setIsGenerating] = useState(false);

  const resetVideoData = () => {
    setCurrentAvatar(null);
    setScriptText('');
    setVideoTitle('');
    setVoiceover(null);
    setBackgroundColor('#ffffff');
    setIsCropped(false);
    setParentId(null);
    localStorage.removeItem('savedVideoProject');
  };

  const generatePayload = () => {
    if (currentAvatar) {
      const payload: VideoGenerationPayload = {
        avatarId: currentAvatar.id,
        avatarName: currentAvatar.name,
        avatarUrl:
          currentAvatar.inputImageUrl ??
          (isCropped
            ? currentAvatar.menuCircleImageUrl
            : currentAvatar.menuImageUrl) ??
          '',
        fullFrame: true,
        voiceId: voiceover ? null : currentVoice && currentVoice.voiceId,
        voiceOverId: voiceover && uuid4(),
        voiceOverUrl: voiceover && voiceover.link,
        script: !voiceover ? scriptText : '',
        videoFormat: 'mp4',
        videoWidth: 1024,
        videoHeight: 1024,
        videoTitle,
        dynamic: isDynamic,
        parentId: parentId || undefined
      };

      return payload;
    }
  };

  const validateVideo = () => {
    if (!currentVoice && !voiceover) {
      showError('Please select a voice or upload a voiceover');
      return false;
    }
    if (!currentAvatar) {
      showError('Please select an avatar');
      return false;
    }
    if (!scriptText && !voiceover) {
      showError('Please enter a script or upload a voiceover');
      return false;
    }

    if (
      !voiceover &&
      currentVoice &&
      scriptText.length > Number(currentVoice.maxCharacterLimit ?? 4000)
    ) {
      showError(
        `Your script is too long. For the chosen voice the text cannot be longer than ${
          currentVoice.maxCharacterLimit || 4000
        } characters`
      );
      return false;
    }

    return true;
  };

  const getEstimate = async () => {
    try {
      const payload = generatePayload();
      const data = await generateVideoApi.getCreditsEstimate(
        payload as VideoGenerationPayload
      );

      return data;
    } catch (error) {
      console.error({ creditsError: error });
    }
  };

  const generateVideo = async (
    setIsGeneratePopupOpened: (isOpened: boolean) => void
  ) => {
    setIsGenerating(true);
    try {
      const payload = generatePayload();

      const data = await generateVideoApi.sendVideoRequest(
        payload as VideoGenerationPayload
      );

      if (data) {
        setIsGeneratePopupOpened(false);
        showSuccessMessage('Request successfully received!');
        amplitude.track('express_generate_video_success');
        logEvent(firebaseAnalytics, 'express_generate_video_success');
        setTimeout(() => {
          resetVideoData();
          window.location.href = './gallery?posthog-express=true';
        }, 300);
      }
    } catch (error) {
      const message = getErrorMessage(
        error,
        `We couldn't generate video. Please try again later.`
      );
      showError(message);
    } finally {
      setIsGenerating(false);
      fetchCredits();
    }
  };

  return {
    validateVideo,
    generateVideo,
    isGenerating,
    getEstimate,
    setIsGenerating
  };
};
