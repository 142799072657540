import { appStore } from 'app/store';
import { AvatarItem, avatarStore } from 'entities/avatar';
import { creditStore } from 'entities/credit';
import { FaPlus } from 'react-icons/fa6';
import { IAvatar } from 'shared';

type AvatarListProps = {
  avatars: IAvatar[];
  title: string;
  isCustomTalkingPhoto?: boolean;
};

export const AvatarsList = ({
  avatars,
  title,
  isCustomTalkingPhoto
}: AvatarListProps) => {
  const [currentAvatar, setCurrentAvatar, toggleUploadAvatar] = avatarStore(
    (state) => [
      state.currentAvatar,
      state.setCurrentAvatar,
      state.toggleUploadAvatar
    ]
  );

  const { user, toggleCreateAccModal } = appStore((state) => ({
    user: state.user,
    toggleCreateAccModal: state.toggleCreateAccModal
  }));

  const { planFeatures, toggleFeatureBlockModal } = creditStore((state) => ({
    planFeatures: state.planFeatures,
    toggleFeatureBlockModal: state.toggleFeatureBlockModal
  }));

  const handleUploadPhoto = () => {
    if (!user) return toggleCreateAccModal();

    if (planFeatures?.expressAllowed) {
      toggleUploadAvatar();
    } else {
      toggleFeatureBlockModal('createAvatar');
    }
  };

  return (
    <>
      {avatars && avatars.length > 0 && (
        <div className="yep_ex-mt-2 yep_ex-mb-6">
          <h3 className="yep_ex-text-sm yep_ex-text-black yep_ex-font-semibold yep_ex-mb-2">
            {title}
          </h3>
          <div className="yep_ex-grid yep_ex-grid-cols-3 md:yep_ex-grid-cols-2 yep_ex-gap-2 yep_ex-font-inter">
            {isCustomTalkingPhoto && (
              <div
                className="yep_ex-aspect-square yep_ex-rounded-lg yep_ex-flex yep_ex-items-center yep_ex-justify-center yep_ex-border yep_ex-border-primary-200 yep_ex-bg-primary-100 yep_ex-cursor-pointer"
                onClick={handleUploadPhoto}
              >
                <FaPlus className="yep_ex-text-primary-600 yep_ex-w-6 yep_ex-h-6" />
              </div>
            )}
            {avatars?.map((avatar, inx) => (
              <AvatarItem
                key={inx}
                avatar={avatar}
                onChooseAvatar={(avatar) => setCurrentAvatar(avatar)}
                isActive={currentAvatar?.id === avatar.id}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
