export const checkLengthError = (
  length: number,
  limit: number = 4000,
  onError: (error: string) => void
) => {
  if (length > limit) {
    onError(
      `Your script is too long. For the chosen voice the text cannot be longer than ${limit} characters`
    );
  }
};
