import { create } from 'zustand';

import { AuthSlice, authStoreSlice } from 'features/auth';
import { createSelectors } from 'shared';

export type Store = AuthSlice;

export const appStore = createSelectors(
  create<Store>()((...a) => ({
    ...authStoreSlice(...a)
  }))
);
