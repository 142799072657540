import React from 'react';
import { videoStore } from 'entities/video';
import ColorSelect from 'shared/ui/ColorSelect';
import { avatarStore } from 'entities/avatar';

const BackgroundColorSelect = () => {
  const backgroundColor = videoStore((state) => state.backgroundColor);
  const setBackgroundColor = videoStore((state) => state.setBackgroundColor);
  const currentAvatar = avatarStore((state) => state.currentAvatar);
  return (
    <div className="yep_ex-flex yep_ex-gap-4 yep_ex-items-center">
      <span
        className={`yep_ex-font-semibold yep_ex-text-sm ${
          currentAvatar?.isUploaded ? 'yep_ex-opacity-30' : ''
        }`}
      >
        BG
      </span>
      <ColorSelect
        color={backgroundColor}
        onChange={setBackgroundColor}
        className="yep_ex-w-8 yep_ex-h-8"
        disabled={currentAvatar?.isUploaded}
      />
    </div>
  );
};

export default BackgroundColorSelect;
