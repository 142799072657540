import { httpService, IAvatar, UploadedPhotoDetails } from 'shared';

const avatarsEndpoint = '/avatars';

export const avatarApi = {
  fetchAll: async () => {
    return await httpService.get<IAvatar[]>(avatarsEndpoint);
  },
  createCustomPhoto: async (photoData: UploadedPhotoDetails) => {
    const payload = {
      InputImageUrl: photoData.inputImageUrl,
      Gender: photoData.gender,
      Name: photoData.name,
      Age: photoData.age
    };
    return await httpService.post<IAvatar>(avatarsEndpoint, payload);
  },
  deleteCustomTalkingPhoto: async (avatarId: string) => {
    return await httpService.delete(avatarsEndpoint + '/' + avatarId);
  }
};
