import {
  BsSoundwave,
  BsFillPlayCircleFill,
  BsPauseCircleFill
} from 'react-icons/bs';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { videoStore } from 'entities/video';
import { getTime } from '../lib';
import { usePlayAudio } from 'features/play-audio';
import { useEffect, useRef, useState } from 'react';
import { Range } from 'react-range';
import { VolumeIcon } from './VolumeIcon';
import { useClickOutside } from 'shared';

export const Voiceover = () => {
  const { voiceover, setVoiceover } = videoStore();
  const {
    isPlaying,
    handlePlayAudio,
    handlePauseAudio,
    handleChangeVolume,
    handleSeekTime,
    currentVolume,
    currentTime,
    reset
  } = usePlayAudio();

  const [isVolumeOpened, setIsVolumeOpened] = useState(false);

  const volumeChangerRef = useRef<HTMLDivElement>(null);

  useClickOutside(volumeChangerRef, () => setIsVolumeOpened(false));

  const handleDeleteVoiceover = () => {
    setVoiceover(null);
  };

  useEffect(() => {
    reset();
  }, [voiceover]);

  if (!voiceover) return null;

  const { minutes: currentTimeMinutes, seconds: currentTimeSeconds } =
    getTime(currentTime);
  const { minutes: audioMinutes, seconds: audioSeconds } = getTime(
    voiceover.audio.duration
  );

  return (
    <div className="yep_ex-mr-4 yep_ex-w-full lg:yep_ex-w-[512px] yep_ex-p-4 yep_ex-bg-white yep_ex-rounded-xl yep_ex-border yep_ex-border-neutral-200 yep_ex-justify-start yep_ex-items-start yep_ex-gap-1 yep_ex-inline-flex yep_ex-flex-col">
      {/* <div className="yep_ex-w-full sm:yep_ex-w-[512px] yep_ex-p-4 yep_ex-bg-white yep_ex-rounded-xl yep_ex-border yep_ex-border-neutral-200 yep_ex-justify-start yep_ex-items-start yep_ex-gap-1 yep_ex-inline-flex yep_ex-flex-col"> */}
      <div className="yep_ex-flex yep_ex-gap-4 yep_ex-w-full">
        <div className="yep_ex-min-w-[2rem] yep_ex-h-8 yep_ex-bg-primary-100 yep_ex-rounded-2xl yep_ex-flex yep_ex-justify-center yep_ex-items-center">
          <BsSoundwave className="yep_ex-text-xl yep_ex-text-primary-600" />
        </div>
        <div className="yep_ex-flex-col yep_ex-justify-start yep_ex-items-start yep_ex-inline-flex yep_ex-w-full">
          <div className="yep_ex-mb-4 yep_ex-w-full yep_ex-flex yep_ex-justify-between">
            <div>
              <div className="yep_ex-self-stretch yep_ex-text-neutral-700 yep_ex-text-sm yep_ex-font-medium yep_ex-leading-tight">
                {voiceover.name}
              </div>
              <div className="yep_ex-self-stretch yep_ex-text-neutral-600 yep_ex-text-sm yep_ex-font-normal yep_ex-leading-tight">
                {voiceover.size} KB
              </div>
            </div>
            <button onClick={handleDeleteVoiceover} className="-yep_ex-mt-5">
              <RiDeleteBin6Line />
            </button>
          </div>
        </div>
      </div>
      <div className="sm:yep_ex-flex yep_ex-gap-4 yep_ex-w-full">
        <div className="yep_ex-min-w-[2rem] hidden sm:block" />
        <div className="yep_ex-p-4 yep_ex-bg-neutral-100 yep_ex-rounded-xl yep_ex-items-center yep_ex-gap-4 yep_ex-inline-flex yep_ex-w-full">
          <div className="yep_ex-justify-start yep_ex-items-center yep_ex-flex yep_ex-w-full">
            {isPlaying ? (
              <button onClick={handlePauseAudio} className="yep_ex-mr-2">
                <BsPauseCircleFill className="yep_ex-text-2xl" />
              </button>
            ) : (
              <button
                onClick={() => handlePlayAudio(voiceover.audio, false)}
                className="yep_ex-mr-2"
              >
                <BsFillPlayCircleFill className="yep_ex-text-2xl" />
              </button>
            )}
            <div className="yep_ex-items-center yep_ex-gap-3 yep_ex-inline-flex yep_ex-w-full">
              <div className="yep_ex-text-neutral-700 yep_ex-text-sm yep_ex-font-medium yep_ex-leading-tight yep_ex-whitespace-nowrap yep_ex-mr-2 yep_ex-flex">
                <div className="yep_ex-w-2">{currentTimeMinutes}</div>:
                <div className="yep_ex-w-2">
                  {Math.floor(currentTimeSeconds / 10)}
                </div>
                <div className="yep_ex-w-2 yep_ex-mr-1">
                  {currentTimeSeconds % 10}
                </div>
                /<div className="yep_ex-w-2 yep_ex-ml-1">{audioMinutes}</div>:
                <div className="yep_ex-w-2">
                  {Math.floor(audioSeconds / 10)}
                </div>
                <div className="yep_ex-w-2">{audioSeconds % 10}</div>
              </div>
              <div className="yep_ex-w-full yep_ex-h-2 yep_ex-relative">
                {/* <div className="yep_ex-w-full sm:yep_ex-w-[14rem] yep_ex-h-2 yep_ex-relative"> */}
                <Range
                  step={0.1}
                  min={0}
                  max={voiceover.audio.duration}
                  values={[currentTime]}
                  onChange={(values) => handleSeekTime(values[0])}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      // style={{
                      //   ...props.style
                      // }}
                      className="yep_ex-h-full yep_ex-rounded-lg yep_ex-bg-neutral-200"
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      style={{ ...props.style }}
                      className="yep_ex-w-4 yep_ex-h-4 yep_ex-rounded-full yep_ex-bg-neutral-500"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="yep_ex-p-2 yep_ex-rounded-md yep_ex-justify-center yep_ex-items-center yep_ex-flex yep_ex-relative">
            <button onClick={() => setIsVolumeOpened((state) => !state)}>
              <VolumeIcon currentVolume={currentVolume} />
            </button>
            {isVolumeOpened && (
              <div
                ref={volumeChangerRef}
                className="yep_ex-bg-white yep_ex-absolute -yep_ex-top-5 -yep_ex-left-15 yep_ex-border yep_ex-border-neutral-200 yep_ex-rounded-full yep_ex-py-2 yep_ex-px-3"
              >
                <div className="yep_ex-w-32 yep_ex-h-1 yep_ex-relative">
                  <Range
                    step={0.1}
                    min={0}
                    max={1}
                    values={[currentVolume]}
                    onChange={(values) => handleChangeVolume(values[0])}
                    onFinalChange={() =>
                      setTimeout(() => setIsVolumeOpened(false), 500)
                    }
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style
                        }}
                        className="yep_ex-h-full yep_ex-rounded-lg yep_ex-bg-neutral-200"
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        {...props}
                        style={{ ...props.style }}
                        className="yep_ex-w-3 yep_ex-h-3 yep_ex-rounded-full yep_ex-bg-neutral-500"
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
