import { ReactElement } from 'react';

type Props = {
  title: string;
  isActive: boolean;
  onClick: () => void;
};

export const Tab = ({ title, isActive, onClick }: Props): ReactElement => {
  return (
    <button
      onClick={onClick}
      className={`yep_ex-flex-1 yep_ex-p-2.5 yep_ex-text-sm yep_ex-font-medium
        ${
          isActive
            ? 'yep_ex-border-primary-600 yep_ex-border-b-2 yep_ex-text-primary-600 yep_ex-pb-[calc(0.625rem-1px)]'
            : 'yep_ex-border-gray-300 yep_ex-border-b-[1px] yep_ex-text-gray-600 yep_ex-pb-[calc(0.625rem)]'
        }`}
    >
      {title}
    </button>
  );
};
