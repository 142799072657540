import { useContext, createContext, PropsWithChildren } from 'react';
import {
  HiExclamationCircle,
  HiOutlineCheckCircle,
  HiXCircle
} from 'react-icons/hi';
import { toast } from 'react-toastify';

export const showMessage = (text: string) => {
  toast(text);
};
export const showSuccessMessage = (text: string) => {
  toast.success(text, {
    icon: (
      <HiOutlineCheckCircle
        className="yep_ex-h-6 yep_ex-w-6 yep_ex-text-green-400"
        aria-hidden="true"
      />
    )
  });
};
export const showError = (text: string) => {
  toast.error(text, {
    icon: (
      <HiXCircle
        className="yep_ex-h-6 yep_ex-w-6 yep_ex-text-red-400"
        aria-hidden="true"
      />
    )
  });
};
export const showWarn = (text: string) => {
  toast.warn(text, {
    icon: (
      <HiExclamationCircle
        className="yep_ex-h-6 yep_ex-w-6 yep_ex-text-yellow-400"
        aria-hidden="true"
      />
    )
  });
};
