import React, { ComponentType } from 'react';
import { Loader } from './Loader';

export function withLoader<T extends object>(
  Component: ComponentType<T>,
  isLoading: boolean
) {
  function HOC({
    loaderClassName = '',
    loaderSize = 50,
    ...props
  }: T & { loaderClassName?: string; loaderSize?: number }) {
    return (
      <>
        {isLoading ? (
          <div className="yep_ex-flex yep_ex-justify-center yep_ex-h-96 yep_ex-items-center w-full">
            <Loader size={96} />
          </div>
        ) : (
          <Component {...(props as T)} />
        )}
      </>
    );
  }

  return HOC;
}
