import { videoStore } from 'entities/video';

export const VideoTitle = () => {
  const { videoTitle, setVideoTitle } = videoStore();

  const handleChangeVideoTitle = (title: string) => {
    setVideoTitle(title);
  };

  return (
    <div className="yep_ex-hidden md:yep_ex-block yep_ex-text-neutral-700 yep_ex-overflow-ellipsis yep_ex-min-w-[6rem] lg:yep_ex-min-w-[10rem] whitespace-nowrap yep_ex-overflow-hidden yep_ex-truncate">
      <input
        value={videoTitle}
        onChange={(e) => handleChangeVideoTitle(e.target.value)}
        className="leyep_ex-leading-6 yep_ex-w-40 lg:yep_ex-w-56 xl:yep_ex-w-64 yep_ex-px-3 yep_ex-py-2 yep_ex-text-neutral-700 yep_ex-border yep_ex-bg-neutral-50 yep_ex-border-neutral-300 yep_ex-rounded-md yep_ex-outline-none"
        placeholder="Add video name"
      />
    </div>
  );
};
