import { CanvasWrapper } from 'widgets/Canvas';
import { Sidebar } from './SideBar';
import { TopBar } from 'widgets/Topbar';
import { useState } from 'react';
import { Script } from 'widgets/Script';
import { VoiceoverWidget } from 'widgets/Voiceover';
import { CanvasControls } from 'widgets/CanvasControls';
import { MobileMenu } from './MobileMenu';

const tabs = ['Script', 'Voiceover'];

export const EditorPage = () => {
  const [activeTab, setActiveTab] = useState('Script');

  return (
    <div className="yep_ex-font-inter yep_ex-relative yep_ex-flex yep_ex-flex-col md:yep_ex-h-screen">
      <div
        className={`yep_ex-w-full yep_ex-border-b yep_ex-border-neutral-200 yep_ex-block`}
      >
        <TopBar />
      </div>
      <div
        className={`md:yep_ex-pr-0 xl:yep_ex-px-0 yep_ex-grow yep_ex-h-[calc(100vh-5rem)] md:yep_ex-flex yep_ex-flex yep_ex-relative yep_ex-overflow-y-auto`}
      >
        <div className="yep_ex-grow yep_ex-flex yep_ex-flex-col">
          <CanvasWrapper />
          <div className="yep_ex-hidden md:yep_ex-block">
            <CanvasControls
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={tabs}
            />
            <div className="yep_ex-p-4">
              {activeTab === 'Script' && <Script />}
              {activeTab === 'Voiceover' && <VoiceoverWidget />}
            </div>
          </div>
          <MobileMenu />
        </div>
        <Sidebar />
      </div>
    </div>
  );
};
