import { Modal } from 'shared';
import { FC, useEffect } from 'react';
import { SiOpenai } from 'react-icons/si';
import { ChatHistory } from './ChatHistory';
import { Prompt } from './Prompt';
import { videoStore } from 'entities/video';
import { chatStore } from 'entities/chatgpt';
import { useSendMessage } from '../model';

export const ChatGptModal: FC = () => {
  const scriptText = videoStore((state) => state.scriptText);
  const { addMessage, reset, messages, isChatGPTOpen, toggleChatGPT } =
    chatStore();
  const { sendMessage, isLoading } = useSendMessage();

  const handleSendMessage = async (
    content: string,
    history: boolean = true
  ) => {
    if (!isLoading) {
      await sendMessage(content, history);
    }
  };

  useEffect(() => {
    if (messages.length === 1 && messages[0].role === 'assistant') {
      reset();
      addMessage({
        role: 'assistant',
        content: `===${scriptText}===`
      });
    }
    if (messages.length === 0) {
      addMessage({
        role: 'assistant',
        content: `===${scriptText}===`
      });
    }
  }, [isChatGPTOpen]);

  const handleCloseChatModal = () => {
    toggleChatGPT(false);
  };

  return (
    <Modal
      onClose={handleCloseChatModal}
      open={isChatGPTOpen}
      noPadding
      withCloseButton
      widthClasses="md:yep_ex-rounded-lg yep_ex-w-screen md:yep_ex-w-2/3 xl:yep_ex-w-7/12 max-md:yep_ex-h-screen yep_ex-flex yep_ex-flex-col md:yep_ex-my-8"
      modalClassName="yep_ex-min-h-full yep_ex-justify-center yep_ex-p-0 yep_ex-text-center yep_ex-items-center md:yep_ex-p-4 yep_ex-flex"
    >
      <div className="yep_ex-w-full yep_ex-h-full yep_ex-flex yep_ex-flex-col">
        <div className="yep_ex-px-4 yep_ex-py-5 md:yep_ex-p-6 yep_ex-flex yep_ex-gap-4">
          <div className="yep_ex-p-3 yep_ex-h-fit yep_ex-rounded-[0.625rem] yep_ex-border yep_ex-drop-shadow-sm yep_ex-border-neutral-200">
            <SiOpenai className="yep_ex-w-6 yep_ex-h-6" />
          </div>
          <div>
            <h2 className="yep_ex-mb-1 yep_ex-text-lg yep_ex-font-semibold yep_ex-text-neutral-900">
              GPT4 Video Script Assistant
            </h2>
            <div className="yep_ex-text-sm  yep_ex-text-neutral-600">
              Effortlessly write powerful, compelling scripts with the help of
              Open AI.
            </div>
          </div>
        </div>
        <div className="yep_ex-w-full yep_ex-border-b yep_ex-border-neutral-200" />
        <ChatHistory
          onClose={handleCloseChatModal}
          onSendMessage={handleSendMessage}
          isLoading={isLoading}
        />
        <div className="yep_ex-w-full yep_ex-border-b yep_ex-border-neutral-200" />
        <Prompt onSendMessage={handleSendMessage} isLoading={isLoading} />
      </div>
    </Modal>
  );
};
