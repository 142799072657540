import { SlMagnifier } from 'react-icons/sl';
import { IoIosCloseCircle } from 'react-icons/io';
import { ReactElement } from 'react';

type Props = {
  placeholder?: string;
  onSearch: (value: string) => void;
  searchTerm: string;
};

export const SearchInput = ({
  placeholder,
  searchTerm = '',
  onSearch
}: Props): ReactElement => {
  const handleClear = () => {
    onSearch('');
  };
  return (
    <div className="yep_ex-flex-grow yep_ex-flex yep_ex-items-center yep_ex-border yep_ex-rounded-md yep_ex-px-3 yep_ex-h-10">
      <SlMagnifier className="yep_ex-mr-2 yep_ex-text-gray-500" />
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => onSearch(e.target.value)}
        className="yep_ex-outline-none focus:yep_ex-outline-none yep_ex-ring-0 focus:yep_ex-ring-0 yep_ex-bg-transparent yep_ex-w-full yep_ex-text-sm yep_ex-text-gray-700 yep_ex-border-none"
      />
      {searchTerm && (
        <button onClick={handleClear} className="yep_ex-ml-2">
          <IoIosCloseCircle className="yep_ex-text-gray-700" />
        </button>
      )}
    </div>
  );
};
