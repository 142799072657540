import { useCallback, useEffect, useState } from 'react';

type PlayAudioModel = {
  currentAudio: HTMLAudioElement | null;
  currentVolume: number;
  currentTime: number;
  isPlaying: boolean;
  handlePlayAudio: (audio: HTMLAudioElement, fromStart: boolean) => void;
  handlePauseAudio: () => void;
  handleChangeVolume: (newVolume: number) => void;
  handleSeekTime: (newCurrentTime: number) => void;
  reset: () => void;
};

export const usePlayAudio = (): PlayAudioModel => {
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
    null
  );
  const [currentVolume, setCurrentVolume] = useState(0.5);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePauseAudio = () => {
    if (currentAudio instanceof HTMLAudioElement) {
      currentAudio.pause();
      currentAudio.removeEventListener('ended', () => {
        currentAudio.currentTime = 0;
        setCurrentTime(0);
        setIsPlaying(false);
        setCurrentAudio(null);
      });
      setIsPlaying(false);
    }
  };

  const handlePlayAudio = useCallback(
    (audio: HTMLAudioElement, fromStart: boolean) => {
      if (isPlaying) {
        handlePauseAudio();
        return;
      }

      audio.currentTime = currentTime;

      if (fromStart || audio.currentTime === audio.duration) {
        audio.currentTime = 0;
        setCurrentTime(0);
      }

      audio.volume = currentVolume;
      setCurrentAudio(audio);
      setIsPlaying(true);
      audio.play();
    },
    [currentVolume, currentTime, isPlaying]
  );

  const handleChangeVolume = (newVolume: number) => {
    setCurrentVolume(newVolume);
    if (currentAudio) {
      currentAudio.volume = newVolume;
    }
  };

  const handleSeekTime = (newCurrentTime: number) => {
    setCurrentTime(newCurrentTime);
    if (currentAudio) {
      currentAudio.currentTime = newCurrentTime;
    }
  };

  const reset = () => {
    setCurrentAudio(null);
    setCurrentTime(0);
  };

  useEffect(() => {
    if (currentAudio) {
      currentAudio.addEventListener('ended', handlePauseAudio);
      currentAudio.addEventListener('timeupdate', () =>
        setCurrentTime(currentAudio.currentTime)
      );
    }
  }, [currentAudio]);

  return {
    currentAudio,
    currentVolume,
    currentTime,
    isPlaying,
    handlePlayAudio,
    handlePauseAudio,
    handleChangeVolume,
    handleSeekTime,
    reset
  };
};
