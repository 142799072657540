import { httpService, IPlan, IUser } from 'shared';

export const authApi = {
  me: async () => {
    const { data } = await httpService.get<IUser>('/users/current');

    return data;
  },
  getCurrentPlan: async () => {
    return await httpService.get<IPlan[]>(`/memberships?Active=true`);
  },
  regenerateApiKey: async () => {
    await httpService.post('/users/generatekey', {
      regenerateapikey: true,
      regenerateagentkey: false
    });
  }
};
