import clsx from 'clsx';
import { Tab } from 'features/navigation';

interface TabsGroupProps<T extends Tab = Tab> {
  tabs: T[] | readonly T[];
  activeTab: T['name'];
  toggleTab: (value: any) => void;
  onOpen?: () => any;
}

const TabsGroup = ({ tabs, activeTab, toggleTab, onOpen }: TabsGroupProps) => {
  return (
    <div
      className="yep_ex-w-auto yep_ex-text-gray-700 yep_ex-text-sm yep_ex-text-center yep_ex-border-l yep_ex-border-solid yep_ex-border-neutral-200 yep_ex-bg-white md:yep_ex-block yep_ex-hidden"
      onClick={onOpen}
    >
      <ul className="yep_ex-flex yep_ex-flex-col yep_ex-justify-center yep_ex-mx-auto yep_ex-mt-6 ">
        {tabs
          .filter((t) => !t.mobileOnly)
          .map((t) => {
            const title =
              typeof t.title === 'string' ? t.title : t.title.desktop;
            return (
              <li key={t.name} className={``}>
                <button
                  className={clsx(
                    'yep_ex-py-4 yep_ex-px-4 yep_ex-flex yep_ex-flex-col yep_ex-items-center yep_ex-w-full yep_ex-justify-center yep_ex-mx-auto disabled:yep_ex-opacity-50',
                    t.name === activeTab
                      ? 'yep_ex-bg-neutral-50'
                      : 'yep_ex-bg-none',
                    !t.disabled && 'hover:yep_ex-bg-neutral-50'
                  )}
                  onClick={() => {
                    toggleTab(t.name);
                  }}
                  disabled={t.disabled}
                >
                  <img src={t.icon} className="yep_ex-mb-1.5" alt={title} />
                  {title}
                </button>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default TabsGroup;
