import { FC } from 'react';
import { IMessage } from 'shared';
import { ChatUserMessage } from './ChatUserMessage';
import { ChatAssistantMessage } from './ChatAssistantMessage';

interface ChatMessageProps extends IMessage {
  isPrevSameRole: boolean;
  onClose: () => void;
  onSendMessage: (content: string, history?: boolean) => Promise<void>;
  isLoading: boolean;
  onChunkTextSelect: () => void;
}

export const ChatMessage: FC<ChatMessageProps> = (props) => {
  if (props.role === 'assistant') return <ChatAssistantMessage {...props} />;
  return <ChatUserMessage {...props} />;
};
