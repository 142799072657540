import React from 'react';
// @ts-ignore
import { ReactComponent as Person } from './img/person.svg';
// @ts-ignore
import { ReactComponent as PersonCircle } from './img/person-circle.svg';
import { avatarStore } from 'entities/avatar';

export const CropAvatar = () => {
  const currentAvatar = avatarStore((state) => state.currentAvatar);
  const isCropped = avatarStore((state) => state.isCropped);
  const setIsCropped = avatarStore((state) => state.setIsCropped);
  return (
    <div className=" yep_ex-gap-4 yep_ex-flex yep_ex-items-center yep_ex-pl-4">
      <span
        className={`yep_ex-font-semibold yep_ex-text-sm ${
          currentAvatar?.isUploaded ? 'yep_ex-opacity-30' : ''
        }`}
      >
        Crop
      </span>
      <button
        className={`yep_ex-h-8 yep_ex-w-8 yep_ex-rounded-md yep_ex-flex yep_ex-justify-center yep_ex-border yep_ex-items-center ${
          isCropped ? ' yep_ex-border-neutral-300' : 'yep_ex-border-primary-500'
        }`}
        onClick={() => setIsCropped(false)}
      >
        <Person />
      </button>
      <button
        className={`yep_ex-h-8 yep_ex-w-8 yep_ex-rounded-md yep_ex-flex yep_ex-justify-center yep_ex-border yep_ex-items-center disabled:yep_ex-opacity-50 ${
          isCropped ? 'yep_ex-border-primary-500' : ' yep_ex-border-neutral-300'
        }`}
        onClick={() => setIsCropped(true)}
        disabled={!currentAvatar?.menuCircleImageUrl}
      >
        <PersonCircle />
      </button>
    </div>
  );
};
