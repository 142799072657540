import { AxiosError } from 'axios';
import { VideoGenerationPayload, httpService } from 'shared';

export const generateVideoApi = {
  sendVideoRequest: async (payload: VideoGenerationPayload) => {
    const charge = await httpService.post('/talkingphotos', payload);
    console.log(charge.data.message);
    if (charge instanceof AxiosError) {
      if (charge.response?.data.errorCode === 'NotEnoughCredits') {
        throw new Error(
          'Not enough credits. Please upgrade your plan to continue generating videos.'
        );
      } else throw new Error(charge.response?.data.message);
    }
    if (charge instanceof Error) {
      throw charge;
    }
    return charge.data;
  },
  getCreditsEstimate: async (payload: VideoGenerationPayload) => {
    try {
      const { data } = await httpService.post('/talkingphotos', {
        ...payload,
        draft: true
      });

      return data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data.errorCode === 'NotEnoughCredits') {
          throw new Error(
            'Not enough credits. Please upgrade your plan to continue generating videos.'
          );
        } else throw new Error(error.response?.data.message);
      }
      if (error instanceof Error) {
        throw error;
      }
    }
  }
};
