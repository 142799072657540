import { AvatarOptions, GenderOptions, IAvatar, logger } from 'shared';

export const filterAvatars = (
  avatars: IAvatar[],
  genderFilters: GenderOptions[]
) => {
  return avatars
    .filter((avatar) => {
      if (!genderFilters.length) {
        return true;
      }

      return genderFilters.includes(
        avatar.gender?.toLowerCase() as GenderOptions
      );
    })

    .sort((a, b) => {
      if (a.photorealistic && !b.photorealistic) {
        return -1;
      }
      if (!a.photorealistic && b.photorealistic) {
        return 1;
      }
      if (a.photorealistic && b.photorealistic) {
        return a.name.localeCompare(b.name);
      }

      return 0;
    });
};
