import { PrimaryButton } from 'shared';
import { FC, useState } from 'react';

export const Prompt: FC<{
  onSendMessage: (content: string, history?: boolean) => Promise<void>;
  isLoading: boolean;
}> = ({ onSendMessage, isLoading }) => {
  const [script, setScript] = useState('');

  const handleSendMessage = async () => {
    if (!isLoading) setScript('');
    await onSendMessage(script);
  };

  return (
    <div className="yep_ex-p-6 yep_ex-flex yep_ex-gap-2">
      <input
        type="text"
        placeholder="Enter a prompt..."
        value={script}
        onChange={(e) => setScript(e.target.value)}
        onKeyUp={(e) => e.code === 'Enter' && handleSendMessage()}
        className="yep_ex-w-full yep_ex-rounded-md yep_ex-border yep_ex-border-neutral-300"
      />
      <PrimaryButton
        onClick={handleSendMessage}
        isLoading={isLoading}
        className="yep_ex-w-20 yep_ex-flex yep_ex-justify-center yep_ex-items-center"
        loaderSize={20}
      >
        Enter
      </PrimaryButton>
    </div>
  );
};
