import clsx from 'clsx';
import { useRef, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { HiOutlineMicrophone } from 'react-icons/hi';
import { FiUploadCloud } from 'react-icons/fi';
import { useUploadFile } from 'entities/file';
import { videoStore } from 'entities/video';
import {
  Loader,
  PrimaryButton,
  firebaseAnalytics,
  OutlineButton,
  showError,
  showMessage,
  getErrorMessage,
  addZero,
  Divider
} from 'shared';
import { logEvent } from 'firebase/analytics';
import * as amplitude from '@amplitude/analytics-browser';
import MicRecorder from 'mic-recorder-to-mp3';
import { RecordVoiceover } from './RecordVoiceover';

type AddVoiceoverProps = {
  onClose: () => void;
};

export const AddVoiceover = ({ onClose }: AddVoiceoverProps) => {
  const { setVoiceover } = videoStore();
  const uploadref = useRef<HTMLInputElement>(null);
  const [isVoiceoverLoading, setIsVoiceoverLoading] = useState(false);
  const [isRecord, setIsRecord] = useState(false);

  const { uploadFile } = useUploadFile();

  const handleUpload = async (files: FileList | null) => {
    if (!files) return;
    setIsVoiceoverLoading(true);
    try {
      const file = files[0];
      const data = await uploadFile(files[0]);

      const audio = new Audio(data.mediaLink);
      audio.setAttribute('preload', 'metadata');
      await new Promise<void>((resolve) => {
        const timeoutId = setTimeout(() => {
          showError(
            'Timeout on audio upload. Please try again or use another file'
          );
          resolve();
        }, 10000);

        audio.onloadedmetadata = () => {
          if (audio.duration > 240) {
            showError("Voiceover's length must be less than 4 minutes");
            return;
          } else {
            setVoiceover({
              name: file.name,
              link: data.mediaLink,
              size: Math.floor(file.size / 1024),
              audio
            });
            amplitude.track('express_voiceover_upload');
            logEvent(firebaseAnalytics, 'express_voiceover_upload');

            onClose();
          }
          clearTimeout(timeoutId);
          resolve();
        };
      });
    } catch (error) {
      const message = getErrorMessage(
        error,
        `There was a problem when we tried to upload voiceover. Please try again later.`,
        true
      );
      showError(message);
    } finally {
      setIsVoiceoverLoading(false);
    }
  };

  return (
    <>
      <div className="yep_ex-px-6 yep_ex-pt-6">
        <div className="yep_ex-text-lg yep_ex-font-semibold">Voiceover</div>
        <div className="yep_ex-text-neutral-600 yep_ex-text-sm yep_ex-mb-6">
          Upload or record your own voiceover
        </div>
      </div>
      <Divider />
      <div className="yep_ex-p-4">
        {isRecord ? (
          <RecordVoiceover setIsRecord={setIsRecord} onClose={onClose} />
        ) : (
          <div className="yep_ex-flex yep_ex-justify-center yep_ex-items-center yep_ex-flex-col yep_ex-gap-4 sm:yep_ex-flex-row ">
            <label htmlFor="voiceover">
              <div
                className={clsx(
                  'yep_ex-border yep_ex-rounded-xl yep_ex-w-[311px] sm:yep_ex-w-[270px] yep_ex-h-[142px] sm:yep_ex-h-[230px] yep_ex-flex yep_ex-items-center yep_ex-justify-center yep_ex-cursor-pointer'
                )}
              >
                {isVoiceoverLoading ? (
                  <Loader isButton size={20} />
                ) : (
                  <div className="yep_ex-flex yep_ex-flex-col yep_ex-items-center">
                    <div className="yep_ex-flex yep_ex-items-center yep_ex-justify-center yep_ex-rounded-full yep_ex-bg-neutral-100 yep_ex-size-14 yep_ex-mb-3">
                      <svg
                        width="26"
                        height="23"
                        viewBox="0 0 26 23"
                        stroke="currentColor"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="yep_ex-text-2xl yep_ex-text-neutral-600"
                      >
                        <path
                          d="M8.33325 16.1667L12.9999 11.5M12.9999 11.5L17.6666 16.1667M12.9999 11.5V22M22.3333 17.0333C23.7583 15.8564 24.6666 14.0759 24.6666 12.0833C24.6666 8.53951 21.7937 5.66667 18.2499 5.66667C17.995 5.66667 17.7565 5.53366 17.6271 5.31403C16.1057 2.73231 13.2968 1 10.0833 1C5.25076 1 1.33325 4.91751 1.33325 9.75C1.33325 12.1605 2.30794 14.3433 3.88469 15.9258"
                          stroke="#525252"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>

                    <div className="yep_ex-mb-1">
                      <span className="yep_ex-text-sm yep_ex-font-semibold  yep_ex-text-primary-700">
                        Upload voiceover{' '}
                      </span>
                      <span className="yep_ex-text-sm yep_ex-text-neutral-600">
                        or drag and drop
                      </span>
                    </div>
                    <div className="yep_ex-text-xs yep_ex-text-neutral-600">
                      Audio files only
                    </div>
                  </div>
                )}
              </div>
              <input
                ref={uploadref}
                accept="audio/wav, audio/mp3"
                className="yep_ex-hidden yep_ex-w-0 yep_ex-h-0"
                type="file"
                id="voiceover"
                name="voiceover"
                onChange={(e) => {
                  handleUpload(e.target.files);
                  setTimeout(() => {
                    e.target.value = '';
                  }, 2000);
                }}
              />
            </label>
            <div className="yep_ex-text-xs">or</div>
            <div
              className="yep_ex-border yep_ex-rounded-xl yep_ex-w-[311px] sm:yep_ex-w-[270px] yep_ex-h-[142px] sm:yep_ex-h-[230px] yep_ex-flex yep_ex-items-center yep_ex-justify-center yep_ex-cursor-pointer"
              onClick={() => setIsRecord(true)}
            >
              <div className="yep_ex-flex yep_ex-flex-col yep_ex-items-center">
                <div className="yep_ex-flex yep_ex-items-center yep_ex-justify-center yep_ex-rounded-full yep_ex-bg-neutral-100 yep_ex-size-14 yep_ex-mb-3">
                  <svg
                    width="18"
                    height="25"
                    viewBox="0 0 18 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="yep_ex-text-2xl yep_ex-text-neutral-600"
                    stroke="currentColor"
                  >
                    <path
                      d="M17.1666 10.1663V12.4997C17.1666 17.01 13.5102 20.6663 8.99992 20.6663M0.833252 10.1663V12.4997C0.833252 17.01 4.48959 20.6663 8.99992 20.6663M8.99992 20.6663V24.1663M4.33325 24.1663H13.6666M8.99992 15.9997C7.06692 15.9997 5.49992 14.4327 5.49992 12.4997V4.33301C5.49992 2.40001 7.06692 0.833008 8.99992 0.833008C10.9329 0.833008 12.4999 2.40001 12.4999 4.33301V12.4997C12.4999 14.4327 10.9329 15.9997 8.99992 15.9997Z"
                      stroke="#525252"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="yep_ex-text-sm yep_ex-font-semibold yep_ex-mb-1 yep_ex-text-primary-700">
                  Record voiceover
                </div>
                <div className="yep_ex-text-xs yep_ex-text-neutral-600">
                  Use your microphone
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
