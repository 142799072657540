import { AxiosError } from 'axios';
import { VoicePreviewPayload, httpService } from 'shared';

export const textToSpeechApi = async (payload: VoicePreviewPayload) => {
  const textToSpeech = await httpService
    .post('/speech', payload)
    .catch((err: Error) => err);

  if (textToSpeech instanceof AxiosError) {
    if (textToSpeech.response?.data.errorCode === 'NotEnoughCredits') {
      throw new Error(
        'Not enough credits. Please upgrade your plan to preview this voice'
      );
    } else throw new Error(textToSpeech.response?.data.message);
  }
  if (textToSpeech instanceof Error) {
    throw textToSpeech;
  }
  return textToSpeech;
};
