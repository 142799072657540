import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';

type DropdownProps = {
  options?: any[];
  children?: React.ReactNode;
  label?: string;
  icon: React.ReactNode;
  buttonClassName?: string;
  className?: string;
};

export const Dropdown = ({
  options,
  children,
  label,
  icon,
  buttonClassName = '',
  className
}: DropdownProps) => {
  return (
    <Menu as="div" className={clsx('yep_ex-relative', className)}>
      <Menu.Button
        className={`${buttonClassName} yep_ex-flex yep_ex-items-center yep_ex-justify-center yep_ex-border yep_ex-border-gray-300 yep_ex-rounded-lg yep_ex-py-[9px] yep_ex-px-4 yep_ex-w-full`}
      >
        <span className="yep_ex-text-sm yep_ex-font-inter yep_ex-font-semibold">
          {label}
        </span>
        {icon}
      </Menu.Button>

      <Transition
        enter="yep_ex-transition yep_ex-duration-100 yep_ex-ease-out"
        enterFrom="yep_ex-transform yep_ex-scale-95 yep_ex-opacity-0"
        enterTo="yep_ex-transform yep_ex-scale-100 yep_ex-opacity-100"
        leave="yep_ex-transition yep_ex-duration-75 yep_ex-ease-out"
        leaveFrom="yep_ex-transform yep_ex-scale-100 yep_ex-opacity-100"
        leaveTo="yep_ex-transform yep_ex-scale-95 yep_ex-opacity-0"
      >
        <Menu.Items className="yep_ex-absolute md:yep_ex-w-[350px] w-md:yep_ex-w-[235px] yep_ex-right-0 yep_ex-top-0.5 yep_ex-z-10 yep_ex-mt-0.5 yep_ex-rounded-md yep_ex-bg-white yep_ex-shadow-lg yep_ex-border yep_ex-border-neutral-200">
          {options?.map((option, index) => (
            <Menu.Item key={index}>{option}</Menu.Item>
          ))}
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
