import { IoIosClose } from 'react-icons/io';
import { avatarStore } from 'entities/avatar';

export function SelectedFilters() {
  const { genderFilters, setGenderFilters, typeFilters, setTypeFilters } =
    avatarStore((state) => ({
      genderFilters: state.genderFilters,
      setGenderFilters: state.setGenderFilters,
      typeFilters: state.typeFilters,
      setTypeFilters: state.setTypeFilters
    }));

  return (
    <div className="yep_ex-px-4 yep_ex-flex yep_ex-flex-wrap yep_ex-gap-3 yep_ex-mb-2 yep_ex-text-primary-700 yep_ex-text-xs">
      {genderFilters.map((filter) => (
        <div
          key={filter}
          className="yep_ex-flex items-center yep_ex-bg-primary-50 yep_ex-px-2 yep_ex-py-1 yep_ex-rounded-lg"
        >
          <span>{filter}</span>
          <button
            onClick={() => setGenderFilters(filter)}
            className="yep_ex-ml-1"
          >
            <IoIosClose size={16} />
          </button>
        </div>
      ))}
      {typeFilters.map((filter) => (
        <div
          key={filter}
          className="yep_ex-flex items-center yep_ex-bg-primary-50 yep_ex-px-2 yep_ex-py-1 yep_ex-rounded-lg"
        >
          <span>{filter}</span>
          <button
            onClick={() => setTypeFilters(filter)}
            className="yep_ex-ml-1"
          >
            <IoIosClose size={16} />
          </button>
        </div>
      ))}
    </div>
  );
}
