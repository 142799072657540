import { chatStore } from 'entities/chatgpt';
import { CHAT_BACKEND_URL_WS, getErrorMessage, showError } from 'shared';
import useWebSocket from 'react-use-websocket';
import { useRef, useState } from 'react';

export const useSendMessage = () => {
  const { addMessage, addMessageChunk, clearChunkMessage } = chatStore();
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingTimeout = useRef<number>();
  const { sendJsonMessage } = useWebSocket(
    CHAT_BACKEND_URL_WS + '/chatgpt/ws',
    {
      onClose: () => {
        if (isLoading) {
          setIsLoading(false);
          clearTimeout(isLoadingTimeout.current);

          clearChunkMessage();
          showError(`There was an error when we tried to receive a message`);
        }
      },
      shouldReconnect: () => true,
      onMessage: (e) => {
        clearTimeout(isLoadingTimeout.current);
        isLoadingTimeout.current = setTimeout(() => {
          const chunkMessage = chatStore.getState().chunkMessage;
          if (chunkMessage) {
            addMessage(chunkMessage);
            clearChunkMessage();
          }
          setIsLoading(false);
        }, 1000) as unknown as number;
        const message = JSON.parse(e.data);
        if (
          typeof message !== 'string' ||
          message === 'assistant' ||
          message === ''
        )
          return;

        addMessageChunk(message);
      }
    }
  );

  const sendMessage = async (content: string, history: boolean = true) => {
    try {
      setIsLoading(true);
      addMessage({ role: 'user', content, hidden: !history });

      await sendJsonMessage(
        chatStore.getState().messages.map((m) => ({
          role: m.role,
          content: m.content
        }))
      );
      addMessageChunk('');
    } catch (e) {
      setIsLoading(false);
      clearChunkMessage();
      const message = getErrorMessage(
        e,
        `There was an error when we tried to send a message.`,
        true
      );
      showError(message);
    }
  };

  return { sendMessage, isLoading };
};
