import { useEffect, useState } from 'react';
import { firebaseAnalytics, showMessage } from 'shared';
import { appStore } from 'app/store';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FiCopy } from 'react-icons/fi';
import RegenerateBtn from './RegenerateBtn';
import { logEvent } from 'firebase/analytics';
import * as amplitude from '@amplitude/analytics-browser';
import { creditStore } from 'entities/credit';

export const Developer = () => {
  const { user, regenerateApiKey, toggleCreateAccModal } = appStore();
  const { planFeatures, toggleFeatureBlockModal } = creditStore();

  const [showApiKey, setShowApiKey] = useState(false);
  const [openApiKey, setOpenApiKey] = useState(false);

  const handleApiKeyRequest = () => {
    if (!user) toggleCreateAccModal();

    if (planFeatures?.apiAllowed) {
      regenerateApiKey();
    } else {
      toggleFeatureBlockModal('api');
    }
  };

  useEffect(() => {
    if (user && !user.apiKey && planFeatures?.apiAllowed) {
      regenerateApiKey();
    }
  }, [user]);

  if (!user) return null;

  return (
    <div className="yep_ex-gap-4 flex yep_ex-flex-col yep_ex-px-4">
      <p className="yep_ex-text-sm yep_ex-mb-6">
        Information and resources to help you to integrate with our API. Our
        guides offer step-by-step instructions and expert advice
      </p>
      <div className="yep_ex-flex yep_ex-flex-col yep_ex-gap-y-1 yep_ex-mb-6 yep_ex-rounded-lg md:yep_ex-w-44">
        {planFeatures?.apiAllowed ? (
          <div className="yep_ex-flex yep_ex-flex-col yep_ex-gap-2 yep_ex-max-w-fit">
            <span className="yep_ex-text-sm yep_ex-font-medium yep_ex-text-neutral-700">
              You API Key
            </span>
            <div
              className={`yep_ex-flex yep_ex-gap-2 yep_ex-w-full md:yep_ex-w-60 xl:yep_ex-w-[23rem] yep_ex-h-fit`}
            >
              <div className="yep_ex-relative yep_ex-rounded-md yep_ex-shadow-sm">
                <input
                  type={showApiKey ? 'text' : 'password'}
                  className={`yep_ex-w-full yep_ex-text-neutral-500 yep_ex-border yep_ex-block yep_ex-rounded-md yep_ex-py-2 yep_ex-pl-3 yep_ex-pr-10 yep_ex-bg-neutral-50 yep_ex-border-neutral-300 placeholder:yep_ex-text-gray-400`}
                  readOnly
                  value={user.apiKey}
                />
                <div className="yep_ex-absolute yep_ex-inset-y-0 yep_ex-right-0 yep_ex-flex yep_ex-items-center yep_ex-pr-3">
                  <button onClick={() => setShowApiKey((prev) => !prev)}>
                    {showApiKey ? (
                      <AiOutlineEye className="yep_ex-h-5 yep_ex-w-5 yep_ex-text-gray-400" />
                    ) : (
                      <AiOutlineEyeInvisible className="yep_ex-h-5 yep_ex-w-5 yep_ex-text-gray-400" />
                    )}
                  </button>
                </div>
              </div>
              <button
                className={`yep_ex-border yep_ex-rounded-md yep_ex-p-2.5 yep_ex-h-fit yep_ex-self-end yep_ex-border-neutral-300`}
                onMouseDown={() =>
                  navigator.clipboard
                    .writeText(user.apiKey || '')
                    .then(() => showMessage('API Key Copied'))
                }
              >
                <FiCopy className="yep_ex-w-5 yep_ex-h-5" />
              </button>
            </div>
            <RegenerateBtn />
          </div>
        ) : (
          <button
            className="yep_ex-rounded-md yep_ex-h-fit yep_ex-py-[0.4375rem] yep_ex-bg-primary-700 yep_ex-text-white yep_ex-px-3.5 yep_ex-w-44 lg:yep_ex-w-auto"
            onClick={handleApiKeyRequest}
          >
            Request API Key
          </button>
        )}
      </div>
      <a
        href="https://docs.yepic.ai/reference/homepage"
        rel="noreferrer noopener"
        target="_blank"
        className="yep_ex-max-w-fit yep_ex-inline-flex yep_ex-justify-center yep_ex-items-center yep_ex-rounded-lg yep_ex-mb-4 yep_ex-text-neutral-700 yep_ex-font-semibold yep_ex-text-sm yep_ex-border yep_ex-border-neutral-300 yep_ex-px-3.5 yep_ex-py-2"
        onClick={() => {
          amplitude.track('express_open_api_docs');
          logEvent(firebaseAnalytics, 'express_open_api_docs');
        }}
      >
        API Documentation
      </a>
    </div>
  );
};
