import {
  Dispatch,
  Fragment,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useRef
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { AiOutlineClose } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';

export interface ModalProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  withCloseButton?: boolean;
  title?: string | ReactNode;
  noPadding?: boolean;
  className?: string;
  widthClasses?: string;
  modalClassName?: string;
}

export const Modal = ({
  open,
  onClose,
  withCloseButton = true,
  children,
  noPadding,
  className,
  widthClasses,
  modalClassName
}: PropsWithChildren<ModalProps>) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="yep_ex-relative yep_ex-z-50"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="yep_ex-ease-out yep_ex-duration-300"
          enterFrom="yep_ex-opacity-0"
          enterTo="yep_ex-opacity-100"
          leave="yep_ex-ease-in yep_ex-duration-200"
          leaveFrom="yep_ex-opacity-100"
          leaveTo="yep_ex-opacity-0"
        >
          <div className="yep_ex-fixed yep_ex-inset-0 yep_ex-bg-gray-500 yep_ex-bg-opacity-75 yep_ex-transition-opacity" />
        </Transition.Child>
        <div className="yep_ex-fixed yep_ex-inset-0 yep_ex-z-10 yep_ex-overflow-y-auto">
          <div
            className={
              modalClassName ||
              `yep_ex-flex yep_ex-min-h-full yep_ex-justify-center yep_ex-p-4 yep_ex-text-center yep_ex-items-center sm:yep_ex-p-0`
            }
          >
            <Transition.Child
              as={Fragment}
              enter="yep_ex-ease-out yep_ex-duration-300"
              enterFrom="yep_ex-opacity-0 yep_ex-translate-y-4 sm:yep_ex-translate-y-0 sm:yep_ex-scale-95"
              enterTo="yep_ex-opacity-100 yep_ex-translate-y-0 sm:yep_ex-scale-100"
              leave="yep_ex-ease-in yep_ex-duration-200"
              leaveFrom="yep_ex-opacity-100 yep_ex-translate-y-0 sm:yep_ex-scale-100"
              leaveTo="yep_ex-opacity-0 yep_ex-translate-y-4 sm:yep_ex-translate-y-0 sm:yep_ex-scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  'yep_ex-relative yep_ex-transform yep_ex-overflow-hidden yep_ex-bg-white yep_ex-text-left yep_ex-shadow-xl yep_ex-transition-all yep_ex-rounded-lg sm:yep_ex-my-8',
                  !noPadding && 'yep_ex-p-4 sm:yep_ex-p-6',
                  widthClasses ||
                    'yep_ex-w-11/12 sm:yep_ex-max-w-screen-md md:yep_ex-w-11/12 lg:yep_ex-w-2/3 xl:yep_ex-w-7/12 ',
                  className
                )}
              >
                {withCloseButton && (
                  <button
                    onClick={() => onClose(false)}
                    className="yep_ex-absolute yep_ex-top-6 yep_ex-right-6 yep_ex-z-50"
                  >
                    <IoMdClose className="yep_ex-text-neutral-500 yep_ex-text-2xl" />
                  </button>
                )}
                <div className="yep_ex-w-full yep_ex-h-full">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
