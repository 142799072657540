import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { BASEURL_DOTNET, ENVIRONMENT } from '../config';
import { getAuthToken, getCookieValue, setCookie } from 'shared';
import { appStore } from 'app/store';

interface Tokens {
  accessToken: string;
  accessTokenExpiresOn: string;
  refreshToken: string;
  refreshTokenExpiresOn: string;
}

export const EXPIRED_SESSION_ERROR = 'EXPIRED_SESSION_ERROR';

export const httpService = axios.create({
  baseURL: BASEURL_DOTNET,
  headers: {
    'Content-Type': 'application/json'
  }
});

const refreshToken = async () => {
  const oldRefreshToken = getCookieValue(`${ENVIRONMENT}_yepic_refresh_token`);
  if (!oldRefreshToken) return null;
  const {
    data: { accessToken, accessTokenExpiresOn }
  } = await httpService.post<Tokens>(
    '/users/token/refresh',
    {
      refreshToken: oldRefreshToken
    },
    {
      _retry: true
    } as object
  );

  setCookie(
    `${ENVIRONMENT}_yepic_access_token`,
    accessToken,
    accessTokenExpiresOn
  );
};

let refreshTokenPromise: Promise<void | null> | null = null; // avoid multiple refresh

const errorInterceptor = async (error: any) => {
  const originalRequest: InternalAxiosRequestConfig & { _retry: boolean } =
    error.config;

  if (error instanceof AxiosError) {
    if (originalRequest._retry) {
      setCookie(`${ENVIRONMENT}_yepic_access_token`, '', 0);
      setCookie(`${ENVIRONMENT}_yepic_refresh_token`, '', 0);
      appStore.setState({ user: null });
      return Promise.reject(new AxiosError(EXPIRED_SESSION_ERROR));
    } else if (error.response?.status === 401) {
      originalRequest._retry = true;
      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshToken().finally(() => {
          refreshTokenPromise = null;
        });
      }
      await refreshTokenPromise;
      originalRequest.headers.Authorization = `Bearer ${getCookieValue(
        `${ENVIRONMENT}_yepic_access_token`
      )}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

httpService.interceptors.response.use((res) => res, errorInterceptor);
httpService.interceptors.request.use((config) => {
  const token = getAuthToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});
