import { featureFlags } from 'shared';
import AvatarIcon from './img/avatarIcon.svg';
import VoiceIcon from './img/voiceIcon.svg';
import StyleIcon from './img/styleIcon.svg';
import DevelopersIcon from './img/developersIcon.svg';
import ScriptIcon from './img/scriptIcon.svg';

export interface Tab<Name extends string = string> {
  name: Name;
  title: string | Record<'mobile' | 'desktop', string>;
  icon: string;
  disabled?: boolean;
  mobileOnly?: boolean;
  priority?: number;
  mobilePriority?: number;
}

export const tabs: readonly Tab[] = [
  {
    name: 'avatar',
    title: 'Avatar',
    icon: AvatarIcon
  },
  {
    name: 'voice',
    title: 'Voice',
    icon: VoiceIcon
  },
  {
    name: 'script',
    title: 'Script',
    icon: ScriptIcon,
    mobileOnly: true
  },
  {
    name: 'developers',
    title: {
      mobile: 'Dev',
      desktop: 'Developers'
    },
    icon: DevelopersIcon
  },
  ...(featureFlags.style
    ? [
        {
          name: 'style',
          title: 'Style',
          icon: StyleIcon
        }
      ]
    : [])
] as const;

// eslint-disable-next-line
export type NameT = typeof tabs[number]['name'];
