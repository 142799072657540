import { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { canvasStore } from 'entities/canvas';
import { useFabricCanvas } from 'features/use-fabric-canvas';

const canvasSize = { width: 500, height: 300 };

export const FabricCanvas = () => {
  const canvasEl = useRef<HTMLCanvasElement>(null);
  const { setCanvas } = canvasStore();

  useFabricCanvas();

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasEl.current, {
      width: canvasSize.width,
      height: canvasSize.width / (16 / 9),
      backgroundColor: 'rgb(220,245,245)'
    });

    canvas.setZoom(0.3);
    setCanvas(canvas);

    return () => {
      canvas.dispose();
    };
  }, []);

  return (
    <>
      {/* <div className={`relative overflow-hidden mx-auto h-full `}> */}
      <canvas
        className="yep_ex-block yep_ex-w-full yep_ex-h-full"
        ref={canvasEl}
      />
      {/* </div> */}
    </>
  );
};
